<template>
  <div>
    <v-card rounded="lg" class="mt-4">
      <v-card-title>Scanned Medical Documents</v-card-title>
      <v-card-text v-if="allRecordings.length > 0">
        <v-row>
          <v-col v-for="item in allRecordings" :key="item.id" cols="12" md="4">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card>
                  <v-img v-if="item.url" :src="item.url" max-height="300px">
                    <v-fade-transition>
                      <v-overlay
                        class="d-flex justify-center align-center"
                        v-if="hover"
                        absolute
                        color="black"
                      >
                        <div class="text-center mb-2 text-md-h6">
                          {{ item.fileName }}
                        </div>
                        <div v-if="item.url" class="text-center mb-2">
                          <v-btn
                            block
                            @click="viewItem(item.index)"
                            color="primary"
                            ><v-icon left> open_in_new </v-icon>View</v-btn
                          >
                        </div>
                        <div v-if="item.url" class="text-center">
                          <v-btn
                            block
                            @click="setDownload(item)"
                            color="primary"
                            ><v-icon left> mdi-download </v-icon>Download</v-btn
                          >
                        </div>
                      </v-overlay>
                    </v-fade-transition>
                  </v-img>
                </v-card>
              </template>
            </v-hover>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>No scanned medical documents</v-card-text>
    </v-card>

    <v-dialog v-model="downloadMessage" max-width="400px">
      <v-card>
        <v-card-title
          >HIPPA Notice
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="downloadMessage = false">Close</v-btn>
        </v-card-title>
        <v-card-text>
          Downloading of patient medical information is only intended for use by
          health care providers to facilitate care of patients under their
          supervision. Downloading patient medical information is only
          recommended on hospital or medical group computer systems that meet
          HIPAA compliance standards. The health care provider/ user downloading
          patient medical information is solely responsible for meeting HIPAA
          compliance requirements of all downloaded patient medical
          information/data
          <v-btn
            class="mt-4"
            block
            text
            @click="download(selectedDownload)"
            color="primary"
          >
            Download
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="carouselDialog"
      v-model="carouselDialog"
      hide-overlay
      fullscreen
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Scanned Medical Documents</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="notes()">
              Notes
            </v-btn>
            <v-btn dark text @click="close()">
              Close
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-container>
          <v-tabs
            class="justify-center d-flex"
            v-model="model"
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows
            center-active
            @change="setType()"
            :value="model"
          >
            <v-tab v-for="item of allRecordings" :key="item.index">
              <v-icon left>mdi-file-document-outline</v-icon>
              {{ item.fileName }}
            </v-tab>
          </v-tabs>
          <v-spacer></v-spacer>
          <v-row class="mt-4 d-flex align-center">
            <v-spacer></v-spacer>
            <v-col
              ><v-btn
                color="primary"
                block
                class="white--text"
                :disabled="model <= 0"
                @click="prev()"
              >
                <v-icon left>arrow_back</v-icon> Prev</v-btn
              ></v-col
            >

            <!-- <v-chip label outlined class="ml-2 mr-2"
              >{{ model + 1 }}/{{ allRecordings.length }}</v-chip
            > -->
            <v-col>
              <v-menu offset-y>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                    class="white--text"
                    color="primary"
                    :disabled="allRecordings.length <= 1"
                    v-on="on"
                    v-bind="attrs"
                    block
                    >Jump <v-icon right>mdi-debug-step-over</v-icon></v-btn
                  >
                </template>
                <v-list>
                  <v-list-item
                    @change="model = item.index"
                    v-for="item in allRecordings"
                    :key="item.id"
                    link
                  >
                    <v-list-item-title
                      v-text="item.fileName"
                    ></v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>

            <v-col>
              <v-btn
                class="white--text"
                block
                color="primary"
                :disabled="model === allRecordings.length - 1"
                @click="next()"
                >Next <v-icon right>arrow_forward</v-icon></v-btn
              >
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>

        <v-carousel
          class="carousel-item-doc "
          v-model="model"
          hide-delimiters
          hide-delimiter-background
          :show-arrows="false"
          ref="carousel"
          style="height: 100% !important;"
        >
          <v-carousel-item
            class="carousel-item-doc "
            v-for="(item, index) in allRecordings"
            style="height: 100% !important;"
            :key="item.id"
          >
            <v-sheet color="white" tile class="fill-height">
              <v-row class="fill-height mt-4" justify="center">
                <div
                  class="doc-wrapper"
                  v-if="
                    selectedDocument.type === 'document' ||
                      selectedDocument.type === 'medication'
                  "
                >
                  <div class="text--primary">
                    <v-row>
                      <v-col cols="10">
                        <v-btn
                          @click="downloadMessageCarousel = true"
                          small
                          dark
                          block
                          color="blue"
                        >
                          download
                          <v-icon right>download</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="2">
                        <v-btn
                          small
                          color="primary"
                          block
                          @click="rotateRight(selectedDocument)"
                          :loading="loadingRotation"
                          ><v-icon>mdi-rotate-right-variant</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </div>

                  <v-divider light class="mb-2 mt-2"></v-divider>

                  <InnerImageZoom
                    class="image-container-docs"
                    v-if="selectedDocument.url && selectedDocument.id"
                    :src.sync="allRecordings[index].url"
                  />
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
          <v-dialog v-model="downloadMessageCarousel" max-width="400px">
            <v-card>
              <v-card-title
                >HIPPA Notice
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="downloadMessageCarousel = false"
                  >Close</v-btn
                >
              </v-card-title>
              <v-card-text>
                Downloading of patient medical information is only intended for
                use by health care providers to facilitate care of patients
                under their supervision. Downloading patient medical information
                is only recommended on hospital or medical group computer
                systems that meet HIPAA compliance standards. The health care
                provider/ user downloading patient medical information is solely
                responsible for meeting HIPAA compliance requirements of all
                downloaded patient medical information/data
                <v-btn
                  class="mt-4"
                  block
                  text
                  @click="download(selectedDocument)"
                  color="primary"
                >
                  Download
                </v-btn>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-carousel>
        <v-container>
          <v-bottom-sheet v-model="showNotes" hide-overlay>
            <v-sheet class="text-center" height="auto">
              <v-card>
                <CallNotes
                  :patientId="patientId"
                  :uniqueName="uniqueName"
                  :practiceId="practiceId"
                />
              </v-card>
            </v-sheet>
          </v-bottom-sheet>
        </v-container>
      </v-card>
    </v-dialog>

    <div id="documents" v-show="false">
      <v-row>
        <v-col cols="12" v-for="item in this.allRecordings" :key="item.id">
          <div style="width: 100%;">
            <img :src="item.url" style="width: 100%;" />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'
import InnerImageZoom from 'vue-inner-image-zoom'
import * as fb from '@/firebase'
import CallNotes from '@/components/room/CallNotes'
import { logFileViewed } from '@/Events'
import Axios from 'axios'

export default {
  name: 'Documents',
  components: {
    CallNotes,
    InnerImageZoom
  },
  props: ['patientId', 'uniqueName', 'practiceId'],
  data() {
    return {
      model: 0,
      loadingRotation: false,
      downloadMessage: false,
      downloadMessageCarousel: false,
      selectedDownload: {},
      showNotes: false,
      carouselDialog: false,
      itemsPerPage: 100,
      selectedDocument: {},
      headersRecordings: [
        {
          text: 'Type',
          align: 'start',
          value: 'type'
        },
        { text: 'File Name', value: 'fileName' },
        { text: 'View', value: 'index', align: 'end' }
      ],
      allRecordings: [],
      documents: []
    }
  },
  watch: {
    model: function() {
      this.setType()
    },
    documents: {
      immediate: true,
      handler() {
        this.allRecordings = []
        let count = 0

        for (const item of this.documents) {
          this.allRecordings.push({
            ...item,
            id: item.id,
            index: count,
            type: 'document'
          })

          count++
        }
      }
    }
  },
  mounted() {
    this.$bind(
      'documents',
      fb.db.collection(
        `Practices/${this.practiceId}/Appointments/${this.uniqueName}/Medical Documents`
      )
    )
  },
  methods: {
    rotateRight(item) {
      this.loadingRotation = true
      let httpsReference = fb.storage.refFromURL(item.url)

      let photoRef = httpsReference

      Axios.get(item.url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data])
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)

          let img = new Image()
          img.src = link.href

          let canvas = document.createElement('canvas')
          img.onload = () => {
            let ctx = canvas.getContext('2d')

            canvas.width = img.height
            canvas.height = img.width

            ctx.translate(canvas.width / 2, canvas.height / 2)

            ctx.rotate(Math.PI / 2)
            ctx.drawImage(img, -img.width / 2, -img.height / 2)

            var image = new Image()
            image.src = canvas.toDataURL('image/png')
            Axios.get(image.src, { responseType: 'blob' }).then(response => {
              const imgBlob = new Blob([response.data])

              this.uploadMedicalDocumentsFile(photoRef, imgBlob, item)
            })
          }
        })
        .catch(console.error)
    },
    async uploadMedicalDocumentsFile(ref, img, item) {
      if (!ref) return
      if (ref) {
        const storageRef = fb.storage.ref()
        const photoRef = storageRef.child(ref.location.path)
        const uploadTask = photoRef.put(img)

        await uploadTask.on(
          'state_changed',
          snapshot => {
            console.log(snapshot.state)
          },
          error => {
            console.log(error)
          },
          async () => {
            if (uploadTask.snapshot.state) {
              const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
              // console.log(downloadURL)
              try {
                await fb.db
                  .doc(
                    `Practices/${this.practiceId}/Appointments/${this.uniqueName}/Medical Documents/${item.id}`
                  )
                  .set(
                    {
                      fileName: ref.name,
                      url: downloadURL
                    },
                    { merge: true }
                  )
                this.loadingRotation = false
              } catch (error) {
                console.log(error)
              }
            }
          }
        )
      } else {
        this.error = 'All fields required'
        this.loadingRotation = false
      }
    },
    prev() {
      this.model = this.model - 1
    },
    next() {
      this.model = this.model + 1
    },
    setType() {
      this.selectedDocument = this.allRecordings[this.model]
      logFileViewed(
        fb.auth.currentUser.uid,
        this.practiceId,
        this.selectedDocument.id
      )
    },
    setDownload(item) {
      this.selectedDownload = item
      this.downloadMessage = true
    },
    download(item) {
      Axios.get(item.url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data])
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `${item.fileName}.jpeg`
          link.click()
          URL.revokeObjectURL(link.href)
          this.downloadMessage = false
          this.downloadMessageCarousel = false
        })
        .catch(console.error)
    },
    viewItem(index) {
      this.model = index
      this.selectedDocument = this.allRecordings[this.model]
      logFileViewed(
        fb.auth.currentUser.uid,
        this.practiceId,
        this.selectedDocument.id
      )
      this.carouselDialog = true
    },
    notes() {
      this.showNotes = !this.showNotes
    },

    async imageToBase64(file) {
      await Axios.get(file, { responseType: 'blob' }).then(response => {
        var reader = new FileReader()
        reader.readAsDataURL(response.data)
        // console.log(response.data)
        const baseType = response.data.type
        reader.onload = () => {
          const stringItem = reader.result
            .replace('data:', '')
            .replace(/^.+,/, '')
          const base64 = stringItem
          var srcString = `data:${baseType};base64,${base64}`
          console.log(`srcString: ${srcString}`)
          this.src = srcString

          // console.log(base64)
        }
        reader.onerror = function(error) {
          console.log('Error: ', error)
        }
      })
    },
    close() {
      this.showNotes = false
      this.carouselDialog = false
    }
  }
}
</script>
<style>
audio {
  width: 100%;
}
canvas {
  width: 100%;
}
</style>
