<template>
  <div>
    <v-card-title>
      ECG
      <v-spacer></v-spacer>
      <v-btn color="red" text @click="$emit('close')">Close</v-btn>
    </v-card-title>

    <v-card-subtitle class="mt-0 pt-0 black--text font-weight-bold"
      >Recorded Date: {{ date }}
    </v-card-subtitle>
    <v-card-text>
      <v-row class="justify-center">
        <v-col cols="5" md="2">
          <v-btn
            block
            color="primary"
            class=" white--text"
            :disabled="index <= 0"
            @click="prev"
          >
            <v-icon left>arrow_back</v-icon> Previous</v-btn
          >
        </v-col>
        <v-col cols="1" md="1">
          <v-chip label outlined class="justify-center" style="width: 100%;">
            {{ displayIndex }} of {{ graphsLength }}
          </v-chip>
        </v-col>
        <v-col cols="5" md="2">
          <v-btn
            block
            color="primary"
            class=" white--text"
            :disabled="index >= graphsLength - 1"
            @click="next"
          >
            Next <v-icon right>arrow_forward</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <pdfDialog v-show="selected === 'DAY'" :data="dailyData" :key="index">
        <template v-if="dailyData.length <= 0 || loadingGraph">
          <v-overlay opacity="0" absolute :value="true">
            <v-card-text
              v-if="dailyData.length <= 0 && !loadingGraph"
              class="black--text"
              >No Data</v-card-text
            >
          </v-overlay>
        </template>
      </pdfDialog>
    </v-card-text>
  </div>
</template>

<script>
import * as fb from '@/firebase'
import moment from 'moment'
import pdfDialog from './pdfDialog'

export default {
  name: 'ECGMain',
  props: ['patientId', 'uniqueName', 'practiceId', 'selectedDisplay', 'index'],
  components: {
    pdfDialog
  },
  data() {
    return {
      loadingGraph: true,
      today: new Date(),
      date: new Date(),
      selected: 'DAY',
      data: null,
      displayIndex: 0,
      healthData: null,
      graphs: [],
      graphsLength: 0,
      dailyData: []
    }
  },
  watch: {
    index: function() {
      if (this.data) {
        this.data = this.graphs[this.index]
      }
    },
    data: function() {
      this.displayIndex = this.index + 1
      this.dailyData = []

      this.date = this.data.date
        ? moment(this.data.date.toDate()).format('LLLL')
        : moment(new Date()).format('LLLL')
      let dailyData1 = []
      let dailyData2 = []
      let dailyData3 = []
      let arr = []

      for (let c = 0; c < this.data.values.length; c++) {
        if (this.data.values[c] !== null) {
          arr.push({ x: c / 500, y: this.data.values[c] })
        }
      }

      const len = arr.length / 3

      let chunks = [],
        i = 0,
        n = arr.length
      while (i < n) {
        chunks.push(arr.slice(i, (i += len)))
      }

      const data1 = chunks[0]
      for (let c = 0; c < data1.length; c++) {
        if (data1[c] !== null) {
          dailyData1.push(data1[c])
        }
        i++
      }

      const data2 = chunks[1]
      for (let c = 0; c < data2.length; c++) {
        if (data2[c] !== null) {
          dailyData2.push(data2[c])
        }
        i++
      }

      const data3 = chunks[2]
      for (let c = 0; c < data3.length; c++) {
        if (data3[c] !== null) {
          dailyData3.push(data3[c])
        }
        i++
      }

      this.dailyData.push(dailyData1)
      this.dailyData.push(dailyData2)
      this.dailyData.push(dailyData3)
    }
  },
  async mounted() {
    this.loadingGraph = true

    await fb.db
      .collection(`Patients/${this.patientId}/HealthData`)
      .where('type', '==', 'ELECTROCARDIOGRAM')
      .orderBy('date', 'desc')
      .get()
      .then(snap => {
        this.graphsLength = 0
        for (const doc of snap.docs) {
          this.graphs.push({ ...doc.data() })
          this.graphsLength++
        }
      })
    this.graphsLength

    if (this.graphs.length > 0) {
      this.data = this.graphs[0]
    } else {
      this.data = null
    }
    this.loadingGraph = false
  },
  methods: {
    prev() {
      const val = this.index - 1
      this.$emit('prev', val)
    },
    next() {
      const val = this.index + 1
      this.$emit('next', val)
    }
  }
}
</script>
