<template>
  <div>
    <v-card
      rounded="lg"
      class="mt-4"
      :min-height="$mq !== 'mobile' && showHealthData ? '655px' : ''"
    >
      <v-card-title>
        HealthData
      </v-card-title>
      <v-card-text v-if="showHealthData">
        <v-row>
          <v-col cols="12" md="8">
            <HeartRateMain
              v-show="selectedGraphType.type === 'HR'"
              :patientId="patientId"
              :uniqueName="uniqueName"
              :practiceId="practiceId"
              :selectedDisplay="selectedGraphType.default"
              :data="healthData"
            />
            <BloodPressureMain
              v-show="selectedGraphType.type === 'BP'"
              :patientId="patientId"
              :uniqueName="uniqueName"
              :practiceId="practiceId"
              :selectedDisplay="selectedGraphType.default"
              :data="healthData"
            />
            <BloodGlucoseMain
              v-show="selectedGraphType.type === 'BG'"
              :patientId="patientId"
              :uniqueName="uniqueName"
              :practiceId="practiceId"
              :selectedDisplay="selectedGraphType.default"
              :data="healthData"
            />
            <PulseOxMain
              v-show="selectedGraphType.type === 'PO'"
              :patientId="patientId"
              :uniqueName="uniqueName"
              :practiceId="practiceId"
              :selectedDisplay="selectedGraphType.default"
              :data="healthData"
            />
            <BmiWeightMain
              v-show="selectedGraphType.type === 'BW'"
              :patientId="patientId"
              :uniqueName="uniqueName"
              :practiceId="practiceId"
              :selectedDisplay="selectedGraphType.default"
              :data="healthData"
            />
            <WalkingHeartRateMain
              v-show="selectedGraphType.type === 'WHR'"
              :patientId="patientId"
              :uniqueName="uniqueName"
              :practiceId="practiceId"
              :selectedDisplay="selectedGraphType.default"
              :data="healthData"
            />
            <CardioFitnessMain
              v-show="selectedGraphType.type === 'CFL'"
              :patientId="patientId"
              :uniqueName="uniqueName"
              :practiceId="practiceId"
              :selectedDisplay="selectedGraphType.default"
              :data="healthData"
            />
            <v-card flat>
              <v-card-title
                class="pt-0"
                v-if="selectedGraphType.type === 'ECG'"
              >
                ECG
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  outlined
                  small
                  v-if="selectedGraphType.type === 'ECG'"
                  @click="viewPdf()"
                >
                  Panel View
                </v-btn>
              </v-card-title>

              <ECGMain
                v-show="selectedGraphType.type === 'ECG'"
                :patientId="patientId"
                :uniqueName="uniqueName"
                :practiceId="practiceId"
                :index="ecgIndex"
                :selectedDisplay="selectedGraphType.default"
                @ecgLength="setEcgLength"
              />
            </v-card>
            <v-dialog v-model="showPdf" fullscreen :key="rand">
              <v-card>
                <EcgPdf
                  v-show="showPdf && selectedGraphType.type === 'ECG'"
                  :patientId="patientId"
                  :uniqueName="uniqueName"
                  :practiceId="practiceId"
                  :index="ecgIndex"
                  :selectedDisplay="selectedGraphType.default"
                  @close="showPdf = false"
                  @next="next"
                  @prev="prev"
                  @ecgLength="setEcgLength"
                />
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="12" md="4">
            <v-data-table
              dense
              :headers="graphsHeader"
              :items="graphs"
              item-key="key"
              class="elevation-0"
              hide-default-footer
            >
              <template v-slot:item="{ item }">
                <tr
                  v-if="item.type !== 'ECG'"
                  :style="`background: ${item.color};`"
                >
                  <td
                    style="cursor: pointer;"
                    @mouseover="
                      selectedGraphType = { ...item, default: 'MONTH' }
                    "
                    @mouseleave="selectedGraphType = persistantGraph"
                    @click="persistantGraph = { ...item, default: 'MONTH' }"
                  >
                    <div>
                      {{ item.name }}
                    </div>
                  </td>
                  <td>
                    <div style="float: right;">
                      <v-btn
                        text
                        small
                        color="primary"
                        :outlined="
                          persistantGraph.type === item.type &&
                            persistantGraph.default === 'DAY'
                        "
                        @mouseover="
                          selectedGraphType = { ...item, default: 'DAY' }
                        "
                        @mouseleave="selectedGraphType = persistantGraph"
                        @click="persistantGraph = { ...item, default: 'DAY' }"
                        >D</v-btn
                      >
                      <v-divider vertical></v-divider>
                      <v-btn
                        text
                        small
                        color="primary"
                        :outlined="
                          persistantGraph.type === item.type &&
                            persistantGraph.default === 'WEEK'
                        "
                        @mouseover="
                          selectedGraphType = { ...item, default: 'WEEK' }
                        "
                        @mouseleave="selectedGraphType = persistantGraph"
                        @click="persistantGraph = { ...item, default: 'WEEK' }"
                        >W</v-btn
                      >
                      <v-btn
                        text
                        small
                        color="primary"
                        :outlined="
                          persistantGraph.type === item.type &&
                            persistantGraph.default === 'MONTH'
                        "
                        @mouseover="
                          selectedGraphType = { ...item, default: 'MONTH' }
                        "
                        @mouseleave="selectedGraphType = persistantGraph"
                        @click="persistantGraph = { ...item, default: 'MONTH' }"
                        >M</v-btn
                      >
                      <!-- <v-btn
                        text
                        small
                        color="primary"
                        :outlined="
                          persistantGraph.type === item.type &&
                            persistantGraph.default === 'YEAR'
                        "
                        @mouseover="
                          selectedGraphType = { ...item, default: 'YEAR' }
                        "
                        @mouseleave="selectedGraphType = persistantGraph"
                        @click="persistantGraph = { ...item, default: 'YEAR' }"
                        >Y</v-btn
                      > -->
                    </div>
                  </td>
                </tr>
                <tr
                  :style="`background: ${item.color};cursor: pointer;`"
                  @mouseover="selectedGraphType = { ...item }"
                  @mouseleave="selectedGraphType = persistantGraph"
                  @click="persistantGraph = { ...item }"
                  v-else
                >
                  <td>
                    <div>
                      {{ item.name }}
                    </div>
                  </td>
                  <td>
                    <v-btn
                      color="primary"
                      class=" white--text"
                      :disabled="ecgIndex <= 0"
                      @click="prevIndex()"
                      text
                      small
                    >
                      <v-icon>arrow_back</v-icon></v-btn
                    >

                    <v-chip label outlined small class="justify-center">
                      {{ displayIndex }} / {{ graphsLength }}
                    </v-chip>

                    <v-btn
                      color="primary"
                      class=" white--text"
                      text
                      small
                      :disabled="ecgIndex >= graphsLength - 1"
                      @click="nextIndex()"
                    >
                      <v-icon>arrow_forward</v-icon></v-btn
                    >
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>No data available.</v-card-text>
    </v-card>
  </div>
</template>
<script>
import { db, auth } from '@/firebase'
// import moment from 'moment'
import HeartRateMain from './heartRateCharts/HeartRateMain'
import BloodPressureMain from './bloodPressureCharts/bloodPressureMain'
import BloodGlucoseMain from './bloodGlucoseCharts/bloodGlucoseMain'
import PulseOxMain from './pulseOxCharts/pulseOxMain'
import BmiWeightMain from './bmiWeightCharts/bmiWeightMain'
import WalkingHeartRateMain from './walkingHrCharts/WalkingHeartRateMain'
import CardioFitnessMain from './cardioFitnessCharts/CardioFitnessMain'
import ECGMain from './ecgCharts/EcgMain'
import EcgPdf from './ecgCharts/EcgPdf'

export default {
  name: 'HealthData',
  props: ['patientId', 'uniqueName', 'practiceId'],
  components: {
    HeartRateMain,
    BloodPressureMain,
    BloodGlucoseMain,
    PulseOxMain,
    BmiWeightMain,
    WalkingHeartRateMain,
    CardioFitnessMain,
    ECGMain,
    EcgPdf
  },
  data() {
    return {
      healthData: [],
      displayIndex: 0,
      hrActive: true,
      showPdf: false,
      graphsLength: 0,
      ecgIndex: 0,
      rand: '123',
      showHealthData: false,
      selectedGraphType: {
        name: 'Heart Rate',
        type: 'HR',
        default: 'MONTH'
      },
      persistantGraph: {
        name: 'Heart Rate',
        type: 'HR',
        default: 'MONTH'
      },
      graphsHeader: [
        {
          text: 'Type',
          align: 'start',
          value: 'name'
        },
        {
          text: '',
          align: 'end',
          value: 'actions'
        }
      ],
      graphs: [],
      authUser: null
    }
  },
  watch: {
    healthData: {
      immediate: true,
      handler() {
        if (this.healthData.length > 0 && this.authUser) {
          this.showHealthData = true
          if (
            !this.authUser.healthData.HR &&
            !this.authUser.healthData.WHR &&
            !this.authUser.healthData.BP &&
            !this.authUser.healthData.BG &&
            !this.authUser.healthData.PO &&
            !this.authUser.healthData.BW &&
            !this.authUser.healthData.CFL &&
            !this.authUser.healthData.ECG
          ) {
            this.showHealthData = false
          }

          if (
            this.healthData.some(x => x.type === 'HEART_RATE') &&
            this.authUser.healthData.HR
          ) {
            this.graphs.push({
              key: 1,
              name: 'Heart Rate',
              type: 'HR',
              default: 'MONTH',
              color: ''
            })
          }
          if (
            this.healthData.some(x => x.type === 'WALKING_HEART_RATE') &&
            this.authUser.healthData.WHR
          ) {
            this.graphs.push({
              key: 2,
              name: 'Walking Heart Rate',
              type: 'WHR',
              default: 'MONTH',
              color: ''
            })
          }
          if (
            this.healthData.some(x => x.type === 'BLOOD_PRESSURE_SYSTOLIC') &&
            this.authUser.healthData.BP
          ) {
            this.graphs.push({
              key: 3,
              name: 'Blood Pressure',
              type: 'BP',
              default: 'MONTH',
              color: ''
            })
          }
          if (
            this.healthData.some(x => x.type === 'BLOOD_GLUCOSE') &&
            this.authUser.healthData.BG
          ) {
            this.graphs.push({
              key: 4,
              name: 'Blood Glucose',
              type: 'BG',
              default: 'MONTH',
              color: ''
            })
          }
          if (
            this.healthData.some(x => x.type === 'BLOOD_OXYGEN') &&
            this.authUser.healthData.PO
          ) {
            this.graphs.push({
              key: 5,
              name: 'Pulse Ox',
              type: 'PO',
              default: 'MONTH',
              color: ''
            })
          }
          if (
            this.healthData.some(x => x.type === 'BODY_MASS_INDEX') &&
            this.authUser.healthData.BW
          ) {
            this.graphs.push({
              key: 6,
              name: 'BMI & Weight',
              type: 'BW',
              default: 'MONTH',
              color: ''
            })
          }
          if (
            this.healthData.some(x => x.type === 'CARDIO_FITNESS_LEVEL') &&
            this.authUser.healthData.CFL
          ) {
            this.graphs.push({
              key: 7,
              name: 'Cardio Fitness Level',
              type: 'CFL',
              default: 'MONTH',
              color: ''
            })
          }
          if (
            this.healthData.some(x => x.type === 'ELECTROCARDIOGRAM') &&
            this.authUser.healthData.ECG
          ) {
            this.graphs.push({
              key: 8,
              name: 'ECG',
              type: 'ECG',
              default: 'DAY',
              color: ''
            })
          }
          this.persistantGraph = { ...this.graphs[0] }
        }
      }
    },
    ecgIndex: {
      immediate: true,
      handler() {
        this.displayIndex = this.ecgIndex + 1
      }
    }
  },
  async mounted() {
    const currentUserId = await auth.currentUser.uid
    this.$bind('authUser', db.collection('Proxies').doc(currentUserId))
    this.$bind(
      'healthData',
      await db
        .collection('Patients')
        .doc(this.patientId)
        .collection('HealthData')
    )
  },
  methods: {
    setEcgLength(len) {
      this.graphsLength = len
    },
    nextIndex() {
      this.ecgIndex++
    },
    prevIndex() {
      this.ecgIndex--
    },
    next(val) {
      this.ecgIndex = val
    },
    prev(val) {
      this.ecgIndex = val
    },
    viewPdf() {
      var result = ''
      var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var charactersLength = 3
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      this.rand = result
      this.showPdf = true
    }
  }
}
</script>
