export function handleTrackDisabled(track, participant) {
  track.on('disabled', () => {
    if (track.kind === 'audio') {
      if (participant.sid) {
        // console.log(`${participant.sid}: muted`)
        const elem = document.querySelector(
          `#remoteTrack #${participant.sid} .muteSvg .svg`
        )
        const domElem = document.querySelector(
          `#domTrack #${participant.sid} .muteSvg .svg`
        )
        if (typeof domElem !== 'undefined' && domElem !== null) {
          domElem.classList.remove('hidden')
        }
        if (typeof elem !== 'undefined' && elem !== null) {
          elem.classList.remove('hidden')
        }
      }
    } else if (track.kind === 'video') {
      if (participant.sid) {
        // console.log(`${participant.sid}: hidden`)

        const domElem = document.querySelector(
          `#domTrack #${participant.sid} .videoSvg .svg`
        )
        if (typeof domElem !== 'undefined' && domElem !== null) {
          domElem.classList.remove('hidden')
        }

        const elem = document.querySelector(
          `#remoteTrack #${participant.sid} .videoSvg .svg`
        )
        if (typeof elem !== 'undefined' && elem !== null) {
          elem.classList.remove('hidden')
        }

        const video = document.querySelector(
          `#remoteTrack #${participant.sid} video`
        )

        video.classList.add('hidden')

        const domVideo = document.querySelector(
          `#domTrack #${participant.sid} video`
        )
        if (typeof domVideo !== 'undefined' && domVideo !== null) {
          domVideo.classList.add('hidden')
        }
      }
    }
  })
}

export function handleTrackEnabled(track, participant) {
  track.on('enabled', () => {
    /* Hide the associated <video> element and show an avatar image. */
    if (track.kind === 'audio') {
      // console.log(`${participant.sid}: unmuted`)
      const elem = document.querySelector(
        `#remoteTrack #${participant.sid} .muteSvg .svg`
      )
      const domElem = document.querySelector(
        `#domTrack #${participant.sid} .muteSvg .svg`
      )
      if (typeof domElem !== 'undefined' && domElem !== null) {
        domElem.classList.add('hidden')
        // console.log('domElem hidden')
      }
      if (typeof elem !== 'undefined' && elem !== null) {
        elem.classList.add('hidden')
      }
    } else if (track.kind === 'video') {
      // console.log(`${participant.sid}: shown`)
      const video = document.querySelector(
        `#remoteTrack #${participant.sid} video`
      )
      video.classList.remove('hidden')

      const elem = document.querySelector(
        `#remoteTrack #${participant.sid} .videoSvg .svg`
      )
      if (typeof elem !== 'undefined' && elem !== null) {
        elem.classList.add('hidden')
      }

      const domElem = document.querySelector(
        `#domTrack #${participant.sid} .videoSvg .svg`
      )
      if (typeof domElem !== 'undefined' && domElem !== null) {
        domElem.classList.add('hidden')
      }

      const domVideo = document.querySelector(
        `#domTrack #${participant.sid} video`
      )
      if (typeof domVideo !== 'undefined' && domVideo !== null) {
        domVideo.classList.remove('hidden')
        domVideo.classList.remove('blackBg')
      }
    }
  })
}

export function attachParticipanctTracks(participant, track, div) {
  div.appendChild(track.attach())
  const elem = document.querySelector(`#${participant.sid} .muteSvg .svg`)
  if (typeof elem !== 'undefined' && elem !== null) {
    elem.classList.add('hidden')
  }
  const elem2 = document.querySelector(`#${participant.sid} .videoSvg .svg`)
  if (typeof elem2 !== 'undefined' && elem2 !== null) {
    elem2.classList.add('hidden')
  }
}

export function handleParticipantDisconnected(participant) {
  if (participant.sid) {
    var id = participant.sid
  }
  const elem = document.querySelector('#' + id)
  const domElem = document.querySelector(`#domTrack #${id}`)
  participant.tracks.forEach(publication => {
    if (publication.track) {
      publication.track.detach()
    }
  })
  if (typeof elem !== 'undefined' && elem !== null) {
    elem.remove()
  }
  if (typeof domElem !== 'undefined' && domElem !== null) {
    domElem.remove()
  }
}

export function handleDominantSpeakerChanged(
  participant,
  room,
  currentSpeaker,
  indicators
) {
  if (currentSpeaker && room.participants.size !== 1) {
    const currentSpeaker = document.querySelector(
      `#remoteTrack #${currentSpeaker}`
    )
    if (typeof currentSpeaker !== 'undefined' && currentSpeaker !== null) {
      currentSpeaker.classList.remove('hide')
    }
  }
  const participantSid = participant.sid ? participant.sid : ''

  if (participantSid && participantSid !== '') {
    currentSpeaker = participantSid
  }

  if (currentSpeaker) {
    const newCurrentSpeaker = document.querySelector(
      `#remoteTrack #${currentSpeaker}`
    )
    if (
      typeof newCurrentSpeaker !== 'undefined' &&
      newCurrentSpeaker !== null
    ) {
      newCurrentSpeaker.classList.add('hide')
    }
  }
  const existing = document.querySelector('#domTrack')
  if (typeof existing !== 'undefined' && existing !== null) {
    existing.innerHTML = ''
  }
  const div = document
    .getElementById('domTrack')
    .appendChild(document.createElement('div'))
  if (participantSid) {
    div.id = participantSid
    div.style.background = 'black'
    div.innerHTML =
      '<div class="name">' + participant.identity + '</div>' + indicators
  }
  participant.tracks.forEach(publication => {
    if (publication.isSubscribed) {
      const track = publication.track
      if (track['kind'] === 'data') {
        track.on('message', data => {
          console.log(data)
        })
      } else {
        const video = document.querySelector(
          `#domTrack #${participantSid} video`
        )
        if (typeof video !== 'undefined' && video !== null) {
          video.classList.remove('hide')
        }

        const elem = document.querySelector(
          `#domTrack #${participantSid} .muteSvg .svg`
        )
        if (typeof elem !== 'undefined' && elem !== null) {
          elem.classList.add('hidden')
        }
        const elem2 = document.querySelector(
          `#domTrack #${participantSid} .videoSvg .svg`
        )
        if (typeof elem2 !== 'undefined' && elem2 !== null) {
          elem2.classList.add('hidden')
        }
        div.appendChild(track.attach())
      }
    }
  })

  participant.on('trackSubscribed', track => {
    if (track['kind'] !== 'data') {
      div.appendChild(track.attach())
      const elem = document.querySelector(
        `#domTrack #${participantSid} .muteSvg .svg`
      )
      if (typeof elem !== 'undefined' && elem !== null) {
        elem.classList.add('hidden')
      }
      const elem2 = document.querySelector(
        `#domTrack #${participantSid} .videoSvg .svg`
      )
      if (typeof elem2 !== 'undefined' && elem2 !== null) {
        elem2.classList.add('hidden')
      }
    }
  })
}

export function attachLocalParticipantTrack(track, room, indicators) {
  const localMediaContainer = document
    .getElementById('remoteTrack')
    .appendChild(document.createElement('div'))
  localMediaContainer.id = room.localParticipant.sid
  localMediaContainer.classList.remove('hide')
  localMediaContainer.innerHTML = '<div class="name">You</div>' + indicators
  // '<div class="name">' + room.localParticipant.identity + '</div>'
  localMediaContainer.appendChild(track.attach())
  const elem = document.querySelector(
    `#${room.localParticipant.sid} .muteSvg .svg`
  )
  if (typeof elem !== 'undefined' && elem !== null) {
    elem.classList.add('hidden')
  }
  const elem2 = document.querySelector(
    `#${room.localParticipant.sid} .videoSvg .svg`
  )
  if (typeof elem2 !== 'undefined' && elem2 !== null) {
    elem2.classList.add('hidden')
  }
}

export function handleRoomDisconnected(room) {
  const id = room.localParticipant.sid
  const elem = document.querySelector('#' + id)

  room.localParticipant.audioTracks.forEach(publication => {
    publication.track.stop()
    publication.unpublish()
  })
  room.localParticipant.videoTracks.forEach(publication => {
    publication.track.stop()
    publication.unpublish()
  })
  room.localParticipant.tracks.forEach(publication => {
    publication.track.detach()
  })
  if (typeof elem !== 'undefined' && elem !== null) {
    elem.remove()
  }

  const existing = document.querySelector('#domTrack')

  if (typeof existing !== 'undefined' && existing !== null) {
    existing.innerHTML = ''
  }
}
