<template>
  <div>
    <!-- <Yearly :chartData="chartData" /> -->
    <div>
      <v-card flat v-show="selected === 'DAY'">
        <v-card-title class="pt-0"
          >BMI & Weight
          <v-spacer></v-spacer>
          <v-card-subtitle
            class="mt-0 pt-0 black--text font-weight-bold"
            style="text-align: right;"
          >
            {{ dayCount }} / 30 Days
          </v-card-subtitle>
        </v-card-title>
        <v-card-subtitle class="mt-0 pt-0 black--text font-weight-bold"
          >Date: {{ computedToday }}</v-card-subtitle
        >
        <v-card-text>
          <Daily :data="dailyBMIData" :dataTwo="dailyWeightData">
            <template
              v-if="
                (dailyBMIData.length <= 0 && dailyWeightData.length <= 0) ||
                  loadingGraph
              "
            >
              <v-overlay opacity="0" absolute :value="true">
                <v-card-text
                  v-if="
                    dailyBMIData.length <= 0 &&
                      dailyWeightData.length <= 0 &&
                      !loadingGraph
                  "
                  class="black--text"
                  >No Data</v-card-text
                >
              </v-overlay>
            </template>
          </Daily>
        </v-card-text>
      </v-card>

      <v-card flat v-show="selected === 'MONTH'">
        <v-card-title class="pt-0"
          >BMI & Weight
          <v-spacer></v-spacer>
          <v-card-subtitle
            class="mt-0 pt-0 black--text font-weight-bold"
            style="text-align: right;"
          >
            {{ dayCount }} / 30 Days
          </v-card-subtitle>
        </v-card-title>
        <v-card-subtitle class="mt-0 pt-0 black--text font-weight-bold"
          >Month: {{ computedMonth }}</v-card-subtitle
        >
        <v-card-text>
          <Monthly :data="monthlyBMIData" :dataTwo="monthlyWeightData">
            <template
              v-if="
                (monthlyBMIData.length <= 0 && monthlyWeightData.length <= 0) ||
                  loadingGraph
              "
            >
              <v-overlay opacity="0" absolute :value="true">
                <v-card-text
                  v-if="
                    monthlyBMIData.length <= 0 &&
                      monthlyWeightData.length <= 0 &&
                      !loadingGraph
                  "
                  class="black--text"
                  >No Data</v-card-text
                >
              </v-overlay>
            </template>
          </Monthly>
        </v-card-text>
      </v-card>

      <v-card flat v-show="selected === 'WEEK'">
        <v-card-title class="pt-0"
          >BMI & Weight
          <v-spacer></v-spacer>
          <v-card-subtitle
            class="mt-0 pt-0 black--text font-weight-bold"
            style="text-align: right;"
          >
            {{ dayCount }} / 30 Days
          </v-card-subtitle>
        </v-card-title>
        <v-card-subtitle class="mt-0 pt-0 black--text font-weight-bold"
          >Week Of: {{ computedWeek }}</v-card-subtitle
        >
        <v-card-text>
          <Weekly :data="weeklyBMIData" :dataTwo="weeklyWeightData">
            <template
              v-if="
                (weeklyBMIData.length <= 0 && weeklyWeightData.length <= 0) ||
                  loadingGraph
              "
            >
              <v-overlay opacity="0" absolute :value="true">
                <v-card-text
                  v-if="
                    weeklyBMIData.length <= 0 &&
                      weeklyWeightData.length <= 0 &&
                      !loadingGraph
                  "
                  class="black--text"
                  >No Data</v-card-text
                >
              </v-overlay>
            </template>
          </Weekly>
        </v-card-text>
      </v-card>

      <!-- <v-card flat v-show="selected === 'YEAR'">
        <v-card-title class="pt-0"
          >BMI & Weight
          <v-spacer></v-spacer>
          <v-card-subtitle
            class="mt-0 pt-0 black--text font-weight-bold"
            style="text-align: right;"
          >
            {{ dayCount }} / 30 Days
          </v-card-subtitle>
        </v-card-title>
        <v-card-subtitle class="mt-0 pt-0 black--text font-weight-bold"
          >Year: {{ computedYear }}</v-card-subtitle
        >
        <v-card-text>
          <Yearly :data="yearlyBMIData" :dataTwo="yearlyWeightData">
            <template
              v-if="
                (yearlyBMIData.length <= 0 && yearlyWeightData.length <= 0) ||
                  loadingGraph
              "
            >
              <v-overlay opacity="0" absolute :value="true">
                <v-card-text
                  v-if="
                    yearlyBMIData.length <= 0 &&
                      yearlyWeightData.length <= 0 &&
                      !loadingGraph
                  "
                  class="black--text"
                  >No Data</v-card-text
                >
              </v-overlay>
            </template>
          </Yearly>
        </v-card-text>
      </v-card> -->

      <v-overlay
        v-if="loadingGraph"
        class="d-flex justify-center align-center"
        opacity="0.4"
        absolute
        :value="true"
      >
        <v-progress-circular indeterminate color="white"></v-progress-circular>
        <span class="ml-4 white--text">Loading Data</span>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Daily from './Daily'
// import Yearly from './Yearly'
import Monthly from './Monthly'
import Weekly from './Weekly'

export default {
  name: 'BmiWeightMain',
  props: ['patientId', 'uniqueName', 'practiceId', 'selectedDisplay', 'data'],
  components: {
    // Yearly,
    Monthly,
    Daily,
    Weekly
  },
  data() {
    return {
      loadingGraph: false,
      today: new Date(),
      selected: 'MONTH',
      healthData: [],
      healthDataTwo: [],
      dailyBMIData: [],
      dailyWeightData: [],
      monthlyBMIData: [],
      monthlyWeightData: [],
      weeklyBMIData: [],
      weeklyDiastoliData: [],
      yearlyBMIData: [],
      yearlyWeightData: [],
      dayCount: 0
    }
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        if (this.data) {
          const data = this.data.filter(x => x.type == 'BODY_MASS_INDEX')
          this.healthData = data
        }
      }
    },
    dataTwo: {
      immediate: true,
      handler() {
        if (this.data) {
          const dataTwo = this.data.filter(x => x.type == 'WEIGHT')
          this.healthDataTwo = dataTwo
        }
      }
    },
    selectedDisplay: {
      immediate: true,
      handler() {
        if (this.selectedDisplay === 'MONTH') {
          this.selected = 'MONTH'
        } else if (this.selectedDisplay === 'WEEK') {
          this.selected = 'WEEK'
        } else if (this.selectedDisplay === 'DAY') {
          this.selected = 'DAY'
        } else if (this.selectedDisplay === 'YEAR') {
          this.selected = 'YEAR'
        }
      }
    },
    healthData: {
      immediate: true,
      handler() {
        this.getDailyBMIData()
        this.getWeeklyBMIData()
        this.getMonthlyData()
        this.getYearlyData()
      }
    },
    healthDataTwo: {
      immediate: true,
      handler() {
        this.getDailyBMIData()
        this.getWeeklyBMIData()
        this.getMonthlyData()
        this.getYearlyData()
      }
    }
  },
  methods: {
    getDailyBMIData() {
      let dailyBMIData = []
      let dailyWeightData = []
      const filteredDailyBMIData = this.healthData.filter(x =>
        moment(x.date.toDate()).isSame(
          moment(this.today).subtract(1, 'day'),
          'day'
        )
      )
      for (const item of filteredDailyBMIData) {
        const date = moment(item.date.toDate())
        const dateU = moment(item.date.toDate()).format('lll')

        dailyBMIData.push({ x: date, y: item.value, date: dateU })
      }
      const filteredDailyWeightData = this.healthDataTwo.filter(x =>
        moment(x.date.toDate()).isSame(
          moment(this.today).subtract(2, 'day'),
          'day'
        )
      )
      for (const item of filteredDailyWeightData) {
        const date = moment(item.date.toDate())
        const dateU = moment(item.date.toDate()).format('lll')
        dailyWeightData.push({ x: date, y: item.value, date: dateU })
      }
      this.dailyBMIData = dailyBMIData.sort((a, b) =>
        a.date < b.date ? 1 : -1
      )
      this.dailyWeightData = dailyWeightData.sort((a, b) =>
        a.date < b.date ? 1 : -1
      )
    },
    getWeeklyBMIData() {
      let weeklyBMIData = []
      let weeklyWeightData = []

      const filteredWeeklyBMIData = this.healthData.filter(x =>
        moment(x.date.toDate()).isBetween(
          moment(this.today).subtract(7, 'days'),
          moment(this.today),
          'day'
        )
      )
      for (const item of filteredWeeklyBMIData) {
        const date = moment(item.date.toDate())
        const dateU = moment(item.date.toDate()).format('lll')
        weeklyBMIData.push({ x: date, y: item.value, date: dateU })
      }
      const filteredWeeklyWeightData = this.healthDataTwo.filter(x =>
        moment(x.date.toDate()).isBetween(
          moment(this.today).subtract(7, 'days'),
          moment(this.today),
          'day'
        )
      )
      for (const item of filteredWeeklyWeightData) {
        const date = moment(item.date.toDate())
        const dateU = moment(item.date.toDate()).format('lll')
        weeklyWeightData.push({ x: date, y: item.value, date: dateU })
      }
      this.weeklyBMIData = weeklyBMIData.sort((a, b) =>
        a.date < b.date ? 1 : -1
      )
      this.weeklyWeightData = weeklyWeightData.sort((a, b) =>
        a.date < b.date ? 1 : -1
      )
    },
    getMonthlyData() {
      let monthlyBMIData = []
      let monthlyWeightData = []
      // const filteredMonthlyData = this.healthData.filter(x =>
      //   moment(x.date.toDate()).isBetween(
      //     moment(new Date()).startOf('month'),
      //     moment(new Date()).endOf('month')
      //   )
      // )
      // const filteredMonthlyDataTwo = this.healthDataTwo.filter(x =>
      //   moment(x.date.toDate()).isBetween(
      //     moment(new Date()).startOf('month'),
      //     moment(new Date()).endOf('month')
      //   )
      // )
      for (const item of this.healthData) {
        const date = moment(item.date.toDate())
        const dateU = moment(item.date.toDate()).format('lll')

        monthlyBMIData.push({ x: date, y: item.value, date: dateU })
      }
      for (const item of this.healthDataTwo) {
        const date = moment(item.date.toDate())
        const dateU = moment(item.date.toDate()).format('lll')

        monthlyWeightData.push({ x: date, y: item.value, date: dateU })
      }
      this.calculateTotalData(monthlyBMIData)

      this.monthlyBMIData = monthlyBMIData.sort((a, b) =>
        a.date < b.date ? 1 : -1
      )
      this.monthlyWeightData = monthlyWeightData.sort((a, b) =>
        a.date < b.date ? 1 : -1
      )
    },
    getYearlyData() {
      let yearlyBMIData = []
      let yearlyWeightData = []

      const filteredBMIYearlyData = this.healthData.filter(x =>
        moment(x.date.toDate()).isBetween(
          moment(new Date()).startOf('year'),
          moment(new Date()).endOf('year')
        )
      )
      for (const item of filteredBMIYearlyData) {
        const date = moment(item.date.toDate())
        const dateU = moment(item.date.toDate()).format('lll')
        yearlyBMIData.push({ x: date, y: item.value, date: dateU })
      }
      const filteredWeightYearlyData = this.healthDataTwo.filter(x =>
        moment(x.date.toDate()).isBetween(
          moment(new Date()).startOf('year'),
          moment(new Date()).endOf('year')
        )
      )
      for (const item of filteredWeightYearlyData) {
        const date = moment(item.date.toDate())
        const dateU = moment(item.date.toDate()).format('lll')
        yearlyWeightData.push({ x: date, y: item.value, date: dateU })
      }
      this.yearlyBMIData = yearlyBMIData.sort((a, b) =>
        a.date < b.date ? 1 : -1
      )
      this.yearlyWeightData = yearlyWeightData.sort((a, b) =>
        a.date < b.date ? 1 : -1
      )
    },
    calculateTotalData(monthlyData) {
      let dayCount = 0
      let last = moment().day()
      monthlyData.sort((a, b) => (a.x > b.x ? 1 : -1))
      for (const item of monthlyData) {
        if (moment(item.x).day() !== last) {
          dayCount++
        }
        last = moment(item.x).day()
      }
      this.dayCount = dayCount
    }
  },
  computed: {
    computedToday: {
      get() {
        return this.today ? moment(this.today).format('MMMM D, YYYY') : ''
      },
      set() {}
    },
    computedMonth: {
      get() {
        return this.today ? moment(this.today).format('MMMM, YYYY') : ''
      },
      set() {}
    },
    computedWeek: {
      get() {
        return this.today ? moment(this.today).format('MMMM DD, YYYY') : ''
      },
      set() {}
    },
    computedYear: {
      get() {
        return this.today ? moment(this.today).format('YYYY') : ''
      },
      set() {}
    }
  }
}
</script>
