<template>
  <div>
    <v-card-subtitle class="mt-0 pt-0 black--text font-weight-bold"
      >Recorded Date: {{ date }}
    </v-card-subtitle>
    <v-card-text>
      <Daily v-show="selected === 'DAY'" :data="dailyData">
        <template v-if="dailyData.length <= 0 || loadingGraph">
          <v-overlay opacity="0" absolute :value="true">
            <v-card-text
              v-if="dailyData.length <= 0 && !loadingGraph"
              class="black--text"
              >No Data</v-card-text
            >
          </v-overlay>
        </template>
      </Daily>
    </v-card-text>
  </div>
</template>

<script>
import * as fb from '@/firebase'
import moment from 'moment'
import Daily from './Daily'

export default {
  name: 'ECGMain',
  props: ['patientId', 'uniqueName', 'practiceId', 'selectedDisplay', 'index'],
  components: {
    Daily
  },
  data() {
    return {
      loadingGraph: true,
      today: new Date(),
      selected: 'DAY',
      data: null,
      date: new Date(),
      healthData: null,
      graphs: [],
      dailyData: []
    }
  },
  watch: {
    index: {
      immediate: true,
      handler() {
        if (this.data) {
          this.displayIndex = this.index + 1
          this.data = this.graphs[this.index]
        }
      }
    },
    data: function() {
      this.date = this.data.date
        ? moment(this.data.date.toDate()).format('LLLL')
        : moment(new Date()).format('LLLL')
      let arr = []

      for (let c = 0; c < this.data.values.length; c++) {
        if (this.data.values[c] !== null) {
          arr.push({ x: c / 1000, y: this.data.values[c] })
        }
      }

      this.dailyData = arr
    }
  },
  async mounted() {
    this.loadingGraph = true

    await fb.db
      .collection(`Patients/${this.patientId}/HealthData`)
      .where('type', '==', 'ELECTROCARDIOGRAM')
      .orderBy('date', 'desc')
      .get()
      .then(snap => {
        let graphsLength = 0

        for (const doc of snap.docs) {
          this.graphs.push({ ...doc.data() })
          graphsLength++
        }
        graphsLength
        this.$emit('ecgLength', graphsLength)
      })
    if (this.graphs.length > 0) {
      this.data = this.graphs[0]
    } else {
      this.data = null
    }

    this.loadingGraph = false
  },
  methods: {}
}
</script>
