<template>
  <div id="exam-page">
    <v-row>
      <v-col :cols="$mq === 'mobile' ? '6' : ''">
        <v-card rounded="lg">
          <v-card-title>
            <v-icon color="red" class="mr-2">mdi-heart-pulse</v-icon>
            <div v-if="appointment.vitals.pulse !== null">
              {{ appointment.vitals.pulse }}
              <span class="caption">bpm</span>
            </div>
            <div v-else>--</div>
          </v-card-title>
        </v-card>
      </v-col>

      <v-col :cols="$mq === 'mobile' ? '6' : ''">
        <v-card rounded="lg">
          <v-card-title>
            <v-icon color="blue" class="mr-2">mdi-battery-heart-outline</v-icon>
            <div v-if="appointment.vitals.pulseox !== null">
              {{ appointment.vitals.pulseox }}
              <span class="caption">%</span>
            </div>
            <div v-else>--</div>
          </v-card-title>
        </v-card>
      </v-col>

      <v-col :cols="$mq === 'mobile' ? '6' : ''">
        <v-card rounded="lg">
          <v-card-title>
            <v-icon color="pink" class="mr-2">mdi-lungs</v-icon>
            <div v-if="appointment.vitals.resprate !== null">
              {{ appointment.vitals.resprate }}
              <span class="caption">bths/min</span>
            </div>
            <div v-else>--</div>
          </v-card-title>
        </v-card>
      </v-col>

      <v-col :cols="$mq === 'mobile' ? '6' : ''">
        <v-card rounded="lg">
          <v-card-title>
            <v-icon color="red" class="mr-2">mdi-thermometer</v-icon>
            <div v-if="appointment.vitals.temperature !== null">
              {{ appointment.vitals.temperature }}
              <span class="caption">&#176; F</span>
            </div>
            <div v-else>--</div>
          </v-card-title>
        </v-card>
      </v-col>

      <v-col :cols="$mq === 'mobile' ? '6' : ''">
        <v-card rounded="lg">
          <v-card-title>
            <v-icon color="red" class="mr-2">mdi-heart</v-icon>
            <div
              v-if="
                appointment.vitals.systolic !== null &&
                  appointment.vitals.diastolic !== null &&
                  appointment.vitals.systolic &&
                  appointment.vitals.diastolic
              "
            >
              {{ appointment.vitals.systolic }} <span>/</span>
              {{ appointment.vitals.diastolic }}
              <span class="caption">mmHg</span>
            </div>

            <div v-else>--</div>
          </v-card-title>
        </v-card>
      </v-col>

      <v-col :cols="$mq === 'mobile' ? '6' : ''">
        <v-card rounded="lg">
          <v-card-title>
            <v-icon color="orange" class="mr-2"
              >mdi-emoticon-happy-outline</v-icon
            >
            <div v-if="appointment.vitals.painscale !== null">
              {{ appointment.vitals.painscale }}
            </div>
            <div v-else>--</div>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-card rounded="lg" class="mt-4 mb-2">
      <v-card-title>Medical History</v-card-title>
      <v-card-text
        id="medicalHistory"
        v-if="
          medicalHistory &&
            appointment.medicalHistory.length > 0 &&
            appointment.medicalHistoryQuestions
        "
        class="black--text"
      >
        <v-data-table
          dense
          :headers="headers"
          :items="medicalHistory"
          item-key="index"
          class="elevation-1"
          :items-per-page="itemsPerPage"
          hide-default-footer
        ></v-data-table>
      </v-card-text>
      <v-card-text
        v-else-if="appointment.medicalHistory.length > 0"
        class="black--text"
      >
        {{ appointment.medicalHistory }}
      </v-card-text>
      <v-card-text v-else>Medical history unavailable</v-card-text>
    </v-card>

    <v-card rounded="lg" class="mt-4">
      <v-card-title>Symptoms</v-card-title>
      <v-card-text id="symptoms" v-if="symptoms.length > 0" class="black--text">
        <v-data-table
          dense
          :headers="headers2"
          :items="symptoms"
          item-key="index"
          class="elevation-1"
          :items-per-page="itemsPerPage"
          hide-default-footer
        ></v-data-table>
      </v-card-text>
      <v-card-text v-else>No symptoms</v-card-text>
    </v-card>
    <Documents
      :patientId="patientId"
      :uniqueName="uniqueName"
      :practiceId="practiceId"
    />
    <Medications
      :patientId="patientId"
      :uniqueName="uniqueName"
      :practiceId="practiceId"
    />
    <Recordings
      :patientId="patientId"
      :uniqueName="uniqueName"
      :practiceId="practiceId"
      @ready="loadHealthData = true"
    />
    <HealthData
      v-if="loadHealthData"
      class="mb-8"
      :patientId="patientId"
      :uniqueName="uniqueName"
      :practiceId="practiceId"
    />
  </div>
</template>
<script>
import * as fb from '@/firebase'
import Recordings from './components/Recordings'
import Documents from './components/Documents'
import Medications from './components/Medications'
import HealthData from './components/HealthData'

export default {
  name: 'Exam',
  components: {
    Recordings,
    Documents,
    Medications,
    HealthData
  },
  props: ['patientId', 'uniqueName', 'practiceId'],
  data() {
    return {
      loadHealthData: false,
      itemsPerPage: 100,
      headers: [
        {
          text: 'Question',
          align: 'start',
          sortable: false,
          value: 'question'
        },
        { text: 'Answer', value: 'answer' }
      ],
      headers2: [
        {
          text: 'Symptom',
          align: 'start',
          value: 'symptom'
        },
        { text: 'Category', value: 'category' }
      ],
      appointment: {
        medicalHistory: [],
        medicalHistoryQuestions: [],
        vitals: {}
      },
      medicalHistory: [],
      symptoms: []
    }
  },
  watch: {
    appointment: async function() {
      if (
        this.appointment.medicalHistory &&
        this.appointment.medicalHistoryQuestions
      ) {
        this.medicalHistory = await this.appointment.medicalHistoryQuestions.map(
          (x, i) => {
            return { question: x, answer: this.appointment.medicalHistory[i] }
          }
        )
      }
    }
  },
  async mounted() {
    this.$bind(
      'appointment',
      fb.db.doc(`Practices/${this.practiceId}/Appointments/${this.uniqueName}`)
    )

    await fb.db
      .collection(
        `Practices/${this.practiceId}/Appointments/${this.uniqueName}/Symptoms`
      )
      .get()
      .then(symptoms => {
        this.symptoms = []
        for (const item of symptoms.docs) {
          const data = item.data()
          for (const key of Object.keys(data)) {
            if (data[key]) {
              this.symptoms.push({
                category: item.id,
                symptom: key
              })
            }
          }
        }
      })

    window.scrollTo(0, document.body.scrollHeight)
    window.scrollTo(0, 0)
  }
}
</script>
<style>
audio {
  width: 100%;
}
canvas {
  width: 100%;
}
</style>
