<template>
  <div>
    <!-- <Yearly :chartData="chartData" /> -->
    <div>
      <v-card flat v-show="selected === 'DAY'">
        <v-card-title class="pt-0"
          >Cardio Fitness Level
          <v-spacer></v-spacer>
          <v-card-subtitle
            class="mt-0 pt-0 black--text font-weight-bold"
            style="text-align: right;"
          >
            {{ dayCount }} / 30 Days
          </v-card-subtitle>
        </v-card-title>
        <v-card-subtitle class="mt-0 pt-0 black--text font-weight-bold"
          >Date: {{ computedToday }}</v-card-subtitle
        >
        <v-card-text>
          <Daily :data="dailyData">
            <template v-if="dailyData.length <= 0 || loadingGraph">
              <v-overlay opacity="0" absolute :value="true">
                <v-card-text
                  v-if="dailyData.length <= 0 && !loadingGraph"
                  class="black--text"
                  >No Data</v-card-text
                >
              </v-overlay>
            </template>
          </Daily>
        </v-card-text>
      </v-card>

      <v-card flat v-show="selected === 'MONTH'">
        <v-card-title class="pt-0"
          >Cardio Fitness Level
          <v-spacer></v-spacer>
          <v-card-subtitle
            class="mt-0 pt-0 black--text font-weight-bold"
            style="text-align: right;"
          >
            {{ dayCount }} / 30 Days
          </v-card-subtitle>
        </v-card-title>
        <v-card-subtitle class="mt-0 pt-0 black--text font-weight-bold"
          >Month: {{ computedMonth }}</v-card-subtitle
        >
        <v-card-text>
          <Monthly :data="monthlyData">
            <template v-if="monthlyData.length <= 0 || loadingGraph">
              <v-overlay opacity="0" absolute :value="true">
                <v-card-text
                  v-if="monthlyData.length <= 0 && !loadingGraph"
                  class="black--text"
                  >No Data</v-card-text
                >
              </v-overlay>
            </template>
          </Monthly>
        </v-card-text>
      </v-card>

      <v-card flat v-show="selected === 'WEEK'">
        <v-card-title class="pt-0"
          >Cardio Fitness Level
          <v-spacer></v-spacer>
          <v-card-subtitle
            class="mt-0 pt-0 black--text font-weight-bold"
            style="text-align: right;"
          >
            {{ dayCount }} / 30 Days
          </v-card-subtitle>
        </v-card-title>
        <v-card-subtitle class="mt-0 pt-0 black--text font-weight-bold"
          >Week Of: {{ computedWeek }}</v-card-subtitle
        >
        <v-card-text>
          <Weekly :data="weeklyData">
            <template v-if="weeklyData.length <= 0 || loadingGraph">
              <v-overlay opacity="0" absolute :value="true">
                <v-card-text
                  v-if="weeklyData.length <= 0 && !loadingGraph"
                  class="black--text"
                  >No Data</v-card-text
                >
              </v-overlay>
            </template>
          </Weekly>
        </v-card-text>
      </v-card>

      <!-- <v-card flat v-show="selected === 'YEAR'">
        <v-card-title class="pt-0"
          >Cardio Fitness Level
          <v-spacer></v-spacer>
          <v-card-subtitle
            class="mt-0 pt-0 black--text font-weight-bold"
            style="text-align: right;"
          >
            {{ dayCount }} / 30 Days
          </v-card-subtitle>
        </v-card-title>
        <v-card-subtitle class="mt-0 pt-0 black--text font-weight-bold"
          >Year: {{ computedYear }}</v-card-subtitle
        >
        <v-card-text>
          <Yearly :data="yearlyData">
            <template v-if="yearlyData.length <= 0 || loadingGraph">
              <v-overlay opacity="0" absolute :value="true">
                <v-card-text
                  v-if="yearlyData.length <= 0 && !loadingGraph"
                  class="black--text"
                  >No Data</v-card-text
                >
              </v-overlay>
            </template>
          </Yearly>
        </v-card-text>
      </v-card> -->

      <v-overlay
        v-if="loadingGraph"
        class="d-flex justify-center align-center"
        opacity="0.4"
        absolute
        :value="true"
      >
        <v-progress-circular indeterminate color="white"></v-progress-circular>
        <span class="ml-4 white--text">Loading Data</span>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Daily from './Daily'
// import Yearly from './Yearly'
import Monthly from './Monthly'
import Weekly from './Weekly'

export default {
  name: 'CardioFitnessMain',
  props: ['patientId', 'uniqueName', 'practiceId', 'selectedDisplay', 'data'],
  components: {
    // Yearly,
    Monthly,
    Daily,
    Weekly
  },
  data() {
    return {
      loadingGraph: false,
      today: new Date(),
      selected: 'MONTH',
      healthData: [],
      dailyData: [],
      weeklyData: [],
      monthlyData: [],
      yearlyData: [],
      dayCount: 0
    }
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        if (this.data) {
          const data = this.data.filter(x => x.type == 'CARDIO_FITNESS_LEVEL')
          this.healthData = data
        }
      }
    },
    selectedDisplay: {
      immediate: true,
      handler() {
        if (this.selectedDisplay === 'MONTH') {
          this.selected = 'MONTH'
        } else if (this.selectedDisplay === 'WEEK') {
          this.selected = 'WEEK'
        } else if (this.selectedDisplay === 'DAY') {
          this.selected = 'DAY'
        } else if (this.selectedDisplay === 'YEAR') {
          this.selected = 'YEAR'
        }
      }
    },
    healthData: {
      immediate: true,
      handler() {
        this.dailyData = this.getDailyData()
        this.weeklyData = this.getWeeklyData()
        this.monthlyData = this.getMonthlyData()
        this.yearlyData = this.getYearlyData()
      }
    }
  },

  methods: {
    getDailyData() {
      let dailyData = []
      const filteredDailyData = this.healthData.filter(x =>
        moment(x.date.toDate()).isSame(
          moment(this.today).subtract(1, 'day'),
          'day'
        )
      )
      for (const item of filteredDailyData) {
        const date = moment(item.date.toDate()).startOf('hour')
        const dateU = moment(item.date.toDate()).format('lll')

        dailyData.push({ x: date, y: item.value, date: dateU })
      }
      return dailyData
    },
    getWeeklyData() {
      let weeklyData = []
      const filteredweeklyData = this.healthData.filter(x =>
        moment(x.date.toDate()).isBetween(
          moment(this.today).subtract(7, 'days'),
          moment(this.today),
          'day'
        )
      )
      for (const item of filteredweeklyData) {
        const date = moment(item.date.toDate()).startOf('day')
        const dateU = moment(item.date.toDate()).format('lll')

        weeklyData.push({ x: date, y: item.value, date: dateU })
      }
      return weeklyData
    },
    getMonthlyData() {
      let monthlyData = []
      for (const item of this.healthData) {
        const date = moment(item.date.toDate()).startOf('day')
        const dateU = moment(item.date.toDate()).format('lll')

        monthlyData.push({ x: date, y: item.value, date: dateU })
      }
      this.calculateTotalData(monthlyData)

      return monthlyData
    },
    getYearlyData() {
      let yearlyData = []
      const filteredYearlyData = this.healthData.filter(x =>
        moment(x.date.toDate()).isBetween(
          moment(new Date()).startOf('year'),
          moment(new Date()).endOf('year')
        )
      )
      for (const item of filteredYearlyData) {
        const date = moment(item.date.toDate()).startOf('month')
        const dateU = moment(item.date.toDate()).format('lll')

        yearlyData.push({ x: date, y: item.value, date: dateU })
      }
      return yearlyData
    },
    calculateTotalData(monthlyData) {
      let dayCount = 0
      let last = moment().day()
      monthlyData.sort((a, b) => (a.x > b.x ? 1 : -1))
      for (const item of monthlyData) {
        if (moment(item.x).day() !== last) {
          dayCount++
        }
        last = moment(item.x).day()
      }
      this.dayCount = dayCount
    }
  },
  computed: {
    computedToday: {
      get() {
        return this.today ? moment(this.today).format('MMMM D, YYYY') : ''
      },
      set() {}
    },
    computedMonth: {
      get() {
        return this.today ? moment(this.today).format('MMMM, YYYY') : ''
      },
      set() {}
    },
    computedWeek: {
      get() {
        return this.today ? moment(this.today).format('MMMM DD, YYYY') : ''
      },
      set() {}
    },
    computedYear: {
      get() {
        return this.today ? moment(this.today).format('YYYY') : ''
      },
      set() {}
    }
  }
}
</script>
