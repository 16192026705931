<template>
  <div>
    <v-sheet color="transparent" max-height="290px">
      <v-sparkline
        :key="String(avg)"
        color="red"
        :line-width="0.1"
        :smooth="16"
        :value="heartbeats"
        stroke-linecap="round"
      ></v-sparkline>
    </v-sheet>

    <vue-wave-surfer
      v-if="selectedFile"
      :src="selectedFile"
      :options="options"
      ref="waveform"
    ></vue-wave-surfer>
  </div>
</template>
<script>
export default {
  name: 'AudioVisualizer',
  props: ['selectedFile', 'selectedEcgPoints'],
  data() {
    return {
      isPlaying: false,
      file: '',
      options: { waveColor: 'red' },
      heartbeats: [],
      duration: null
    }
  },
  mounted() {
    this.player.on('ready', async () => {
      await this.getPulse()
      this.isPlaying = true
      this.player.play()
    })
  },
  methods: {
    getPulse() {
      this.heartbeats = []

      var i = 0
      while (i < this.selectedEcgPoints.length) {
        this.heartbeats.push(this.selectedEcgPoints[i] * -1)
        i++
      }
      this.duration = (this.player.getDuration() + 0.5) * 1000
    }
  },
  computed: {
    player() {
      return this.$refs.waveform.waveSurfer
    },
    avg() {
      const sum = this.heartbeats.reduce((acc, cur) => acc + cur, 0)
      const length = this.heartbeats.length

      if (!sum && !length) return 0

      return Math.ceil(sum / length)
    }
  }
}
</script>
