<template>
  <v-card>
    <v-card-title>
      Add Proxy
      <v-spacer></v-spacer>
      <v-btn @click="cancel" text small color="red">Cancel</v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-alert dense type="error" dismissible v-if="error">
          {{ error }}</v-alert
        >
        <v-select
          v-model="selectProxy"
          :items="proxyList"
          item-text="title"
          item-value="id"
          label="Select Proxy"
          :rules="rules"
          @keyup.enter="addProxy"
          required
          return-object
          single-line
        ></v-select>
        <v-btn :disabled="!valid" color="primary" @click="addProxy" block>
          Add Proxy
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import * as fb from '@/firebase'

export default {
  name: 'AddRelativeDialog',
  props: ['practiceId', 'patientId', 'uniqueName'],
  data: () => ({
    error: '',
    valid: true,
    rules: [v => !!v || 'Required'],
    patient: {},
    selectProxy: null,
    proxies: [],
    proxyList: []
  }),
  watch: {
    proxies: function() {
      for (const item of this.proxies) {
        const title = `${item.fname} ${item.lname} - ${item.role
          .charAt(0)
          .toUpperCase() + item.role.slice(1)}`
        this.proxyList.push({ title: title, id: item.id, ...item })
      }
    }
  },
  async mounted() {
    await fb.db
      .doc(`Patients/${this.patientId}`)
      .get()
      .then(snap => {
        this.patient = { ...snap.data() }
      })

    this.$bind(
      'proxies',
      fb.db.collection('Proxies').where('practiceId', '==', this.practiceId)
    )
  },
  methods: {
    cancel() {
      this.$refs.form.reset()
      this.$emit(`close`)
    },
    addProxy() {
      const proxyCallInvite = fb.functions.httpsCallable('proxyCallInvite')
      this.loading = true
      if (this.$refs.form.validate()) {
        // proxyCallInvite
        // console.log({
        //   email: this.selectProxy.email,
        //   userId: this.selectProxy.id,
        //   appointmentPath: `?code=${this.uniqueName}&p=${this.practiceId}`,
        //   patientName: `${this.patient.fname} ${this.patient.lname}`,
        //   doctorName: `${this.selectProxy.fname} ${this.selectProxy.lname}`
        // })
        try {
          proxyCallInvite({
            email: this.selectProxy.email,
            userId: this.selectProxy.id,
            appointmentPath: `?code=${this.uniqueName}&p=${this.practiceId}`,
            patientName: `${this.patient.fname} ${this.patient.lname}`,
            doctorName: `${this.selectProxy.fname} ${this.selectProxy.lname}`
          })
          this.$refs.form.reset()
          this.$emit(`close`)
        } catch (error) {
          this.error = error
          console.log(error)
          this.loading = false
        }
      } else {
        this.error = 'All Fields Required'
      }
      this.loading = false
    }
  }
}
</script>
