<template>
  <div>
    <div v-if="auth.currentUser && currentUser">
      <ProxyRoomLayout
        :proxy="currentUser"
        v-if="
          currentUser.role.toLowerCase() == 'doctor' ||
            currentUser.role.toLowerCase() == 'nurse' ||
            currentUser.role.toLowerCase() == 'staff'
        "
      />
      <PatientRoomLayout
        :user="currentUser"
        v-else-if="currentUser.role.toLowerCase() == 'patient'"
      />
    </div>
    <div v-else-if="!auth.currentUser">
      <PublicRoomLayout />
    </div>
  </div>
</template>

<script>
import { auth } from '@/firebase'
import ProxyLayout from '@/layouts/ProxyLayout'
import PatientLayout from '@/layouts/PatientLayout'
import PublicLayout from '@/layouts/PublicLayout'
import ProxyRoomLayout from '@/components/room/layouts/ProxyRoomLayout'
import PatientRoomLayout from '@/components/room/layouts/PatientRoomLayout'
import PublicRoomLayout from '@/components/room/layouts/PublicRoomLayout'

export default {
  name: 'Room',
  props: ['user'],
  components: {
    ProxyRoomLayout,
    PatientRoomLayout,
    PublicRoomLayout
  },
  data() {
    return {
      currentUser: null,
      auth: auth
    }
  },
  watch: {
    user: {
      immediate: true,
      async handler() {
        if (!this.user && !auth.currentUser) {
          this.$emit(`update:layout`, PublicLayout)
        }
        if (this.user) {
          if (this.user.role.toLowerCase() === 'patient') {
            this.currentUser = this.user
            this.$emit(`update:layout`, PatientLayout)
          } else {
            this.currentUser = this.user
            this.$emit(`update:layout`, ProxyLayout)
          }
        }
      }
    }
  }
}
</script>
