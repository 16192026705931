<template>
  <div>
    <div style="position: relative;">
      <canvas id="ecg1" ref="ecg1" style="max-height: 200px !important; ">
      </canvas>
      <slot></slot>
    </div>
    <div style="position: relative; ">
      <canvas id="ecg2" ref="ecg2" style="max-height: 200px !important; ">
      </canvas>
      <slot></slot>
    </div>
    <div style="position: relative; ">
      <canvas id="ecg3" ref="ecg3" style="max-height: 200px !important; ">
      </canvas>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import * as cjs from 'chart.js'
import { registerables } from 'chart.js'
import 'chartjs-adapter-moment'

export default {
  name: 'pdfDialog',
  props: ['data'],
  data() {
    return {
      chartData: [],
      myChart: null,
      myChart2: null,
      myChart3: null,
      loading: true,
      options: {
        responsive: true,
        elements: {
          point: {
            radius: 0
          },
          line: {
            tension: 1
          }
        },
        scales: {
          xAxes: {
            type: 'linear'
          },
          yAxes: {
            display: true,
            ticks: {
              beginAtZero: true
            },
            scaleLabel: {
              display: true,
              labelString: 'Y axis label'
            }
          }
        }
      }
    }
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        this.loading = true
        this.chartData = this.data
      }
    },

    chartData: function() {
      this.loading = true
      this.fillData()
    },
    loading: function() {
      this.$emit('graphLoading', this.loading)
    }
  },
  created() {
    cjs.Chart.register(...registerables)
  },
  mounted() {
    this.loading = true
    this.fillData()
  },
  methods: {
    fillData() {
      this.loading = false
      if (this.myChart) {
        this.myChart.destroy()
      }
      this.myChart = new cjs.Chart(this.$refs.ecg1, {
        type: 'line',
        data: {
          datasets: [
            {
              label: 'Panel 1',
              borderColor: 'red',
              backgroundColor: 'red',
              borderWidth: 1,
              data: this.chartData[0]
            }
          ]
        },
        options: this.options
      })

      if (this.myChart2) {
        this.myChart2.destroy()
      }
      this.myChart2 = new cjs.Chart(this.$refs.ecg2, {
        type: 'line',
        data: {
          datasets: [
            {
              label: 'Panel 2',
              borderColor: 'red',
              backgroundColor: 'red',
              borderWidth: 1,
              data: this.chartData[1]
            }
          ]
        },
        options: this.options
      })

      if (this.myChart3) {
        this.myChart3.destroy()
      }
      this.myChart3 = new cjs.Chart(this.$refs.ecg3, {
        type: 'line',
        data: {
          datasets: [
            {
              label: 'Panel 3',
              borderColor: 'red',
              backgroundColor: 'red',
              borderWidth: 1,
              data: this.chartData[2]
            }
          ]
        },
        options: this.options
      })
    }
  }
}
</script>
