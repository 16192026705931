<template>
  <div class="room" v-if="!pageLoading">
    <v-toolbar v-if="isWaitingRoomOpen" dark color="primary">
      <v-toolbar-title v-if="showUsername"
        ><v-text-field
          v-if="isAuthUser || showLeave"
          v-model="identity"
          label="Enter a Username"
          single-line
          rounded
          clearable
          dense
          hide-details
          outlined
        ></v-text-field
      ></v-toolbar-title>

      <v-toolbar-title v-if="!showUsername">{{ doctorName }}</v-toolbar-title>

      <v-spacer v-if="$mq !== 'mobile'"></v-spacer>

      <v-toolbar-items>
        <div v-if="showLeave" class="call-buttons">
          <v-btn
            v-if="mic"
            @click="micOff"
            dark
            :small="$mq === 'mobile'"
            color="primary"
            elevation="0"
            :disabled="!showLeave"
          >
            <v-icon>mic</v-icon>
          </v-btn>
          <v-btn
            v-else
            @click="micOn"
            dark
            :small="$mq === 'mobile'"
            color="primary"
            elevation="0"
            :disabled="!showLeave"
          >
            <v-icon>mic_off</v-icon>
          </v-btn>
          <v-btn
            v-if="video"
            dark
            color="primary"
            :small="$mq === 'mobile'"
            elevation="0"
            @click="videoOff"
            :disabled="!showLeave"
          >
            <v-icon>videocam</v-icon>
          </v-btn>
          <v-btn
            v-else
            @click="videoOn"
            :disabled="!showLeave"
            :small="$mq === 'mobile'"
            dark
            color="primary"
            elevation="0"
          >
            <v-icon>videocam_off</v-icon>
          </v-btn>
        </div>
        <v-btn
          color="white"
          :loading="loading"
          :disabled="loading"
          text
          dark
          v-if="!showLeave && $mq !== 'mobile'"
          @click="createToken()"
        >
          Join Call
          <v-icon right color="white">phone</v-icon>
        </v-btn>
        <v-btn
          dark
          text
          :small="$mq === 'mobile'"
          v-if="showLeave"
          @click="leaveRoom"
        >
          <span v-if="$mq !== 'mobile'">Leave Call</span>
          <v-icon right color="white">phone_disabled</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-toolbar v-else color="primary" dark>
      <v-toolbar-title
        >Waiting Room for {{ doctorName }} is closed.</v-toolbar-title
      >
    </v-toolbar>

    <div v-if="!showLeave">
      <img id="wrBg" v-if="roomImage" :src="roomImage" />
    </div>
    <v-dialog
      v-if="!showLeave && $mq === 'mobile'"
      v-model="mobileJoinDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading"
            v-if="!showLeave"
            block
            @click="createToken()"
          >
            Join Call
            <v-icon right color="white">phone</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row>
      <v-container>
        <v-col
          v-show="showAudio"
          cols="12"
          style="background: white; min-height: 100px; float: right;"
          class="audio-container"
        >
          <v-btn
            class="close-btn"
            x-small
            elevation="0"
            color="red"
            @click="showAudio = false"
            dark
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <div id="audioPlay">
            <div v-if="testSource !== null">
              <AudioVisualizer
                v-if="testSource"
                :selectedFile="testSource"
                :selectedEcgPoints="testSelectedEcgPoints"
              />
            </div>
          </div>
        </v-col>
      </v-container>
      <v-col cols="12">
        <div id="tracksWrapper">
          <div id="remoteWrapper">
            <div id="remoteTrack"></div>
          </div>
          <div id="domTrack"></div>
        </div>
      </v-col>
    </v-row>

    <FloatingActions v-if="!isDoctor" style="position: fixed;" />
  </div>
</template>

<script>
import * as fb from '@/firebase'
import * as Twilio from 'twilio-video'

import FloatingActions from '@/components/FloatingActions'
import AudioVisualizer from '@/components/AudioVisualizer'
import { eventBus } from '@/main.js'
import * as moment from 'moment'
import * as twilioHandlers from '@/components/room/twilioFunctions'
import beep from '@/assets/beep-06.wav'

export default {
  name: 'PatientRoomLayout',
  props: ['user'],
  components: {
    FloatingActions,
    AudioVisualizer
  },
  data() {
    return {
      mobileJoinDialog: false,
      currentUser: null,
      sendMessageDialog: false,
      enterUserNameDialog: false,
      addProxyDialog: false,
      pageLoading: true,
      showNotes: false,
      isWaitingRoomOpen: true,
      isAuthUser: false,
      error: '',
      mic: true,
      video: true,
      validStart: false,
      recordRules: [v => !!v || 'Required'],
      recordTimes: [6, 12, 18, 30, 60, 90, 120],
      selectedRecTime: 12,
      selectedRecording: '',
      selectedMessage: {},
      dialog: false,
      loading: false,
      data: {},
      localTrack: false,
      currentSpeaker: null,
      activeRoom: '',
      room_name: '',
      identity: '',
      token: '',
      isRecording: false,
      ekoStream: null,
      streamEko: false,
      uniqueName: '',
      showLeave: false,
      showJoin: true,
      showUsername: true,
      practiceId: '',
      patientId: '',
      isDoctor: false,
      isStaff: false,
      doctorName: '',
      doctorEmail: '',
      roomImage: '',
      localTracks: null,
      appointment: {},
      isLocal: true,
      patient: {
        medicalcard: {
          age: '',
          birthdate: '',
          gender: '',
          height: '',
          weight: '',
          address: '',
          pharmacy: ''
        }
      },
      isMobileDevice: false,
      time: null,
      recordings: {},
      autoPlaySrc: '',
      selectedEcgPoints: [0, 0, 0, 0, 0, 0, 0],
      showAudio: false,
      testSource: null,
      testSelectedEcgPoints: [0, 0, 0, 0, 0, 0, 0],
      beepSound: beep,
      domParticipantOn: true,
      indicators: `<div class="muteSvg"><div class="svg"><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><g><title>background</title><rect fill="none" id="canvas_background" height="25" width="25" y="-1" x="-1" /></g><g><title>Layer 1</title><path fill="#d11516" id="svg_2" d="m19,11l-1.7,0c0,0.74 -0.16,1.43 -0.43,2.05l1.23,1.23c0.56,-0.98 0.9,-2.09 0.9,-3.28zm-4.02,0.17c0,-0.06 0.02,-0.11 0.02,-0.17l0,-6c0,-1.66 -1.34,-3 -3,-3s-3,1.34 -3,3l0,0.18l5.98,5.99zm-10.71,-8.17l-1.27,1.27l6.01,6.01l0,0.72c0,1.66 1.33,3 2.99,3c0.22,0 0.44,-0.03 0.65,-0.08l1.66,1.66c-0.71,0.33 -1.5,0.52 -2.31,0.52c-2.76,0 -5.3,-2.1 -5.3,-5.1l-1.7,0c0,3.41 2.72,6.23 6,6.72l0,3.28l2,0l0,-3.28c0.91,-0.13 1.77,-0.45 2.54,-0.9l4.19,4.18l1.27,-1.27l-16.73,-16.73z" /></g></svg></div></div><div class="videoSvg"><div class="svg"><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><g><title>background</title><rect fill="none" id="canvas_background" height="402" width="582" y="-1" x="-1"/></g><g><title>Layer 1</title><path fill="#d11516" id="svg_2" d="m21,6.5l-4,4l0,-3.5c0,-0.55 -0.45,-1 -1,-1l-6.18,0l11.18,11.18l0,-10.68zm-17.73,-4.5l-1.27,1.27l2.73,2.73l-0.73,0c-0.55,0 -1,0.45 -1,1l0,10c0,0.55 0.45,1 1,1l12,0c0.21,0 0.39,-0.08 0.54,-0.18l3.19,3.18l1.27,-1.27l-17.73,-17.73z"/></g></svg></div></div>`,
      indicatorsHidden: `<div class="muteSvg"><div class="svg hidden"><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><g><title>background</title><rect fill="none" id="canvas_background" height="25" width="25" y="-1" x="-1" /></g><g><title>Layer 1</title><path fill="#d11516" id="svg_2" d="m19,11l-1.7,0c0,0.74 -0.16,1.43 -0.43,2.05l1.23,1.23c0.56,-0.98 0.9,-2.09 0.9,-3.28zm-4.02,0.17c0,-0.06 0.02,-0.11 0.02,-0.17l0,-6c0,-1.66 -1.34,-3 -3,-3s-3,1.34 -3,3l0,0.18l5.98,5.99zm-10.71,-8.17l-1.27,1.27l6.01,6.01l0,0.72c0,1.66 1.33,3 2.99,3c0.22,0 0.44,-0.03 0.65,-0.08l1.66,1.66c-0.71,0.33 -1.5,0.52 -2.31,0.52c-2.76,0 -5.3,-2.1 -5.3,-5.1l-1.7,0c0,3.41 2.72,6.23 6,6.72l0,3.28l2,0l0,-3.28c0.91,-0.13 1.77,-0.45 2.54,-0.9l4.19,4.18l1.27,-1.27l-16.73,-16.73z" /></g></svg></div></div><div class="videoSvg"><div class="svg hidden"><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><g><title>background</title><rect fill="none" id="canvas_background" height="402" width="582" y="-1" x="-1"/></g><g><title>Layer 1</title><path fill="#d11516" id="svg_2" d="m21,6.5l-4,4l0,-3.5c0,-0.55 -0.45,-1 -1,-1l-6.18,0l11.18,11.18l0,-10.68zm-17.73,-4.5l-1.27,1.27l2.73,2.73l-0.73,0c-0.55,0 -1,0.45 -1,1l0,10c0,0.55 0.45,1 1,1l12,0c0.21,0 0.39,-0.08 0.54,-0.18l3.19,3.18l1.27,-1.27l-17.73,-17.73z"/></g></svg></div></div>`
    }
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if (this.user) {
          this.currentUser = this.user
          this.mount()
        }
      }
    },
    showLeave: {
      immediate: true,
      handler() {
        if (
          navigator.userAgent.match(/Android/i) ||
          navigator.userAgent.match(/webOS/i) ||
          navigator.userAgent.match(/iPhone/i) ||
          navigator.userAgent.match(/iPad/i) ||
          navigator.userAgent.match(/iPod/i) ||
          navigator.userAgent.match(/BlackBerry/i) ||
          navigator.userAgent.match(/Windows Phone/i)
        ) {
          if (!this.showLeave) {
            this.mobileJoinDialog = true
          } else {
            this.mobileJoinDialog = false
          }
        }

        window.scrollTo(0, 0)
      }
    },
    appointment: function() {
      this.ekoConnected = this.appointment.ekoConnected
    },
    isRecording: function() {
      const time = this.selectedRecTime * 1000
      setTimeout(
        function() {
          this.isRecording = false
          this.stopEko()
        }.bind(this),
        time
      )
    },
    recordings: async function() {
      try {
        this.testSource = null
        this.selectedEcgPoints = [0, 0, 0, 0, 0, 0, 0]
        await new Promise(resolve => setTimeout(resolve, 1))
        if (
          this.recordings.url !== '' &&
          this.recordings.url !== null &&
          this.recordings.url !==
            'https://firebasestorage.googleapis.com/v0/b/vpexam-hhvi-dhp.appspot.com/o/Assets%2Fbeep-06.wav?alt=media&token=c919a1a6-2b81-4c7b-8d12-3b7662131458'
        ) {
          this.autoPlaySrc = this.recordings.url
          this.selectedEcgPoints =
            this.recordings.ecgPoints !== '' &&
            this.recordings.ecgPoints !== null
              ? this.recordings.ecgPoints
              : [0, 0, 0, 0, 0, 0]
          this.testSelectedEcgPoints = this.selectedEcgPoints
          this.testSource = this.autoPlaySrc
        } else {
          this.testSource = this.beepSound
          this.testSelectedEcgPoints = [0, 0, 0, 0, 0, 0, 0]
        }
      } catch (error) {
        this.error2 = error.message
      }
    }
  },
  mounted() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.isMobileDevice = true
      this.mobileJoinDialog = true
    }

    window.scrollTo(0, 0)
  },
  methods: {
    sendMessage() {
      this.selectedMessage = {
        phoneNumber: this.patient.phoneNumber,
        userId: this.patient.id
      }

      this.sendMessageDialog = true
    },
    closeMessageDialog() {
      this.sendMessageDialog = false
    },
    addProxy() {
      this.addProxyDialog = true
    },
    close() {
      this.addProxyDialog = false
    },
    async mount() {
      this.uniqueName = this.$route.query.code
      this.practiceId = this.$route.query.p

      localStorage.called = ''

      await this.$bind(
        'appointment',
        fb.db.doc(
          `Practices/${this.practiceId}/Appointments/${this.uniqueName}`
        )
      )
      this.patientId = this.appointment.patientId
      this.doctorId = this.appointment.doctorId

      const doctor = await fb.proxiesCollection.doc(this.doctorId).get()
      this.doctorEmail = doctor.data().email
      if (doctor.data().wrImageURL) {
        this.roomImage = doctor.data().wrImageURL
      }
      if (doctor.data().isWaitingRoomOpen !== null) {
        this.isWaitingRoomOpen = doctor.data().isWaitingRoomOpen
      }

      this.doctorName = 'Dr. ' + doctor.data().displayName
      this.$bind('patient', fb.db.doc(`Patients/${this.patientId}`))
      if (this.isWaitingRoomOpen) {
        this.identity = this.currentUser.fname + ' ' + this.currentUser.lname
        if (this.isWaitingRoomOpen && !this.isMobileDevice) {
          this.createToken()
        }
      }

      this.pageLoading = false
    },
    async startEko() {
      this.showAudio = true
      this.testSource = this.beepSound
      this.autoPlaySrc = this.testSource
      this.testSelectedEcgPoints = [0, 0, 0, 0, 0, 0, 0, 0]

      try {
        fb.practicesCollection
          .doc(this.practiceId)
          .collection('Appointments')
          .doc(this.uniqueName)
          .set(
            { startRecEkoFromDoc: true, recordTime: this.selectedRecTime },
            { merge: true }
          )

        this.streamEko = true
        this.isRecording = true
      } catch (error) {
        console.log(error)
      } finally {
        await new Promise(resolve => setTimeout(resolve, 2000))
        this.$bind(
          'recordings',
          fb.db.doc(
            `Practices/${this.practiceId}/Appointments/${this.uniqueName}/Live Recordings/liverecording`
          )
        )
      }
    },
    stopEko() {
      this.streamEko = false
      this.isRecording = false
      // this.showAudio = false
    },

    formatBirthDate(date) {
      return moment(date.toDate()).format('MMMM DD, YYYY')
    },
    async createToken() {
      this.loading = true
      const createToken = fb.functions.httpsCallable('createToken')

      try {
        await createToken({
          identity: this.identity,
          uniqueName: this.uniqueName
        }).then(result => {
          this.token = result.data.token
          this.showUsername = false
          this.showLeave = true
          this.showJoin = false
          eventBus.$emit('showControls')
          this.enterUserNameDialog = false
          this.twilioConnect()
        })
        this.error = ''
      } catch (error) {
        this.error = error.message
      }
      this.loading = false
    },
    // Leave Room.
    leaveRoom() {
      if (this.activeRoom) {
        this.activeRoom.disconnect()
        this.isLocal = false
        this.showLeave = false
        eventBus.$emit('hideControls')
        this.appointment.isWaiting = false
        this.setWaiting()

        const elem = document.querySelector('#remoteTrack')
        if (typeof elem !== 'undefined' && elem !== null) {
          elem.remove()
        }

        if (!this.isDoctor && fb.auth.currentUser) {
          this.$router.push('/my-appointments')
        } else if (!this.isDoctor && !fb.auth.currentUser) {
          this.$router.push('/')
        }

        this.streamEko = false
        const elem2 = document.querySelector('#ekoStream')
        if (typeof elem2 !== 'undefined' && elem2 !== null) {
          elem2.remove()
        }
      }
    },

    // Create a new chat
    async twilioConnect() {
      window.scrollTo(0, 0)
      // disconnect the user from they joined already
      this.leaveRoom()

      this.appointment.isWaiting = true
      this.setWaiting()

      document.getElementById('remoteTrack').innerHTML = ''

      this.showLeave = true

      Twilio.createLocalTracks({
        audio: true,
        video: { width: 640 }
      })
        .then(localTracks => {
          this.localTracks = localTracks
          return Twilio.connect(this.token, {
            name: this.room_name,
            // logLevel: 'debug',
            audio: true,
            video: true,
            dominantSpeaker: true,
            tracks: localTracks
          })
        })
        .then(room => {
          // set active toom
          this.activeRoom = room

          // if local preview is not active, create it
          room.localParticipant.videoTracks.forEach(publication => {
            twilioHandlers.attachLocalParticipantTrack(
              publication.track,
              room,
              this.indicators
            )
            this.localTrack = true
            this.showLeave = true
            this.isLocal = true
          })

          // Attach the Participant's Media to a <div> element.
          room.on('participantConnected', participant => {
            const div = document
              .getElementById('remoteTrack')
              .appendChild(document.createElement('div'))
            div.id = participant.sid
            div.style.background = 'black'
            div.classList.remove('hide')
            div.innerHTML =
              '<div class="name">' +
              participant.identity +
              '</div>' +
              this.indicatorsHidden

            participant.tracks.forEach(publication => {
              if (publication.isSubscribed) {
                const track = publication.track
                if (track['kind'] !== 'data') {
                  div.appendChild(track.attach())
                }
              }
            })

            participant.on('trackSubscribed', track => {
              if (track['kind'] !== 'data') {
                div.appendChild(track.attach())
              }
            })
          })

          room.participants.forEach(participant => {
            // console.log(`${participant}`)
            const div = document
              .getElementById('remoteTrack')
              .appendChild(document.createElement('div'))

            if (participant.sid) {
              div.id = participant.sid
              div.style.background = 'black'
              div.classList.remove('hide')
              div.innerHTML =
                '<div class="name">' +
                participant.identity +
                '</div>' +
                this.indicators
            }

            participant.tracks.forEach(publication => {
              if (publication.isSubscribed) {
                const track = publication.track
                if (track['kind'] !== 'data') {
                  twilioHandlers.attachParticipanctTracks(
                    participant,
                    track,
                    div
                  )
                }
              }
            })

            participant.on('trackSubscribed', track => {
              if (track['kind'] !== 'data') {
                twilioHandlers.attachParticipanctTracks(participant, track, div)
              }
            })
            participant.tracks.forEach(publication => {
              if (publication.isSubscribed) {
                twilioHandlers.handleTrackDisabled(
                  publication.track,
                  participant
                )
                twilioHandlers.handleTrackEnabled(
                  publication.track,
                  participant
                )
              }

              publication.on('subscribed', track => {
                twilioHandlers.handleTrackDisabled(track, participant)
                twilioHandlers.handleTrackEnabled(track, participant)
              })
            })
          })

          room.on('participantDisconnected', participant => {
            twilioHandlers.handleParticipantDisconnected(participant)
            this.appointment.isWaiting = false
            this.setWaiting()
          })

          // Remove localTrack on leave
          room.on('disconnected', room => {
            this.isLocal = false
            this.appointment.isWaiting = false
            this.setWaiting()
            twilioHandlers.handleRoomDisconnected(room)
          })

          if (this.domParticipantOn) {
            room.on('dominantSpeakerChanged', participant => {
              twilioHandlers.handleDominantSpeakerChanged(
                participant,
                room,
                this.currentSpeaker,
                this.indicators
              )
            })
          }

          eventBus.$on('muteMic', () => {
            room.localParticipant.audioTracks.forEach(publication => {
              publication.track.disable()
            })

            const elem = document.querySelector(
              `#${room.localParticipant.sid} .muteSvg .svg`
            )
            if (typeof elem !== 'undefined' && elem !== null) {
              elem.classList.remove('hidden')
            }
          })

          eventBus.$on('unmuteMic', () => {
            room.localParticipant.audioTracks.forEach(publication => {
              publication.track.enable()
            })
            const elem = document.querySelector(
              `#${room.localParticipant.sid} .muteSvg .svg`
            )
            if (typeof elem !== 'undefined' && elem !== null) {
              elem.classList.add('hidden')
            }
          })

          eventBus.$on('hideVideo', () => {
            room.localParticipant.videoTracks.forEach(publication => {
              publication.track.disable()
            })
            const elem = document.querySelector(
              `#${room.localParticipant.sid} .videoSvg .svg`
            )
            if (typeof elem !== 'undefined' && elem !== null) {
              elem.classList.remove('hidden')
            }
            const vidElem = document.querySelector(
              `#${room.localParticipant.sid} video`
            )
            if (typeof vidElem !== 'undefined' && vidElem !== null) {
              vidElem.classList.add('overlay')
            }
          })

          eventBus.$on('showVideo', () => {
            room.localParticipant.videoTracks.forEach(publication => {
              publication.track.enable()
            })
            const elem = document.querySelector(
              `#${room.localParticipant.sid} .videoSvg .svg`
            )

            if (typeof elem !== 'undefined' && elem !== null) {
              elem.classList.add('hidden')
            }
            const vidElem = document.querySelector(
              `#${room.localParticipant.sid} video`
            )
            if (typeof vidElem !== 'undefined' && vidElem !== null) {
              vidElem.classList.remove('overlay')
            }
          })
        })
    },
    async setWaiting() {
      if (this.appointment.isWaiting == true) {
        this.sendEmailNotification()
      }

      try {
        console.log(`Hit ${this.appointment.isWaiting}`)
        await fb.practicesCollection
          .doc(this.practiceId)
          .collection('Appointments')
          .doc(this.uniqueName)
          .set(
            {
              isWaiting: this.appointment.isWaiting,
              timeJoined: fb.db.app.firebase_.firestore.Timestamp.fromDate(
                moment().toDate()
              )
            },
            { merge: true }
          )
      } catch (error) {
        console.log(error)
      }
    },
    sendEmailNotification() {
      const waitingRoomEmailNotification = fb.functions.httpsCallable(
        'waitingRoomEmailNotification'
      )

      try {
        fb.patientsCollection
          .doc(this.patientId)
          .get()
          .then(snapshot => {
            const data = snapshot.data()
            const patientName = data.fname + ' ' + data.lname

            if (!this.appointment.adHoc) {
              waitingRoomEmailNotification({
                email: this.doctorEmail,
                userId: this.doctorId,
                appointmentDate: `${this.computedStartTime} - ${this.computedEndTime}`,
                appointmentPath: this.appointment.path,
                patientName: patientName,
                adHoc: false
              })
            } else {
              const birthdate = moment(
                data.medicalcard.birthdate.toDate()
              ).format('LLLL')
              waitingRoomEmailNotification({
                email: this.doctorEmail,
                userId: this.doctorId,
                appointmentDate: `${this.computedStartTime} - ${this.computedEndTime}`,
                appointmentPath: this.appointment.path,
                patientName: patientName,
                birthdate: birthdate,
                doctorName: this.doctorName,
                adHoc: true
              })
            }
          })
      } catch (error) {
        console.log(error)
      }
    },
    micOff() {
      eventBus.$emit('muteMic')
      this.mic = false
    },
    micOn() {
      eventBus.$emit('unmuteMic')
      this.mic = true
    },
    videoOff() {
      eventBus.$emit('hideVideo')
      this.video = false
    },
    videoOn() {
      eventBus.$emit('showVideo')
      this.video = true
    },
    toggleNotes() {
      this.showNotes = !this.showNotes
    }
  },
  computed: {
    computedLastUpdated: {
      get() {
        return this.appointment.vitalStamp
          ? moment(this.appointment.vitalStamp.toDate()).format(
              'MM/DD/YYYY HH:mm A'
            )
          : ''
      },
      set() {}
    },
    computedStartTime: {
      get() {
        return this.appointment.startTime
          ? moment(this.appointment.startTime.toDate()).format(
              'dddd, MMMM Do YYYY hh:mm A'
            )
          : ''
      },
      set() {}
    },
    computedEndTime: {
      get() {
        return this.appointment.endTime
          ? moment(this.appointment.endTime.toDate()).format('hh:mm A')
          : ''
      },
      set() {}
    }
  }
}
</script>

<style>
#wrBg {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  object-fit: cover;
}
#tracksWrapper {
  display: flex;
  max-height: 90vh;
}
#remoteTrack {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  background: black;
}
#remoteTrack > div {
  position: relative;
  flex-basis: 100%;
}
#remoteTrack > div video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#remoteWrapper {
  flex-basis: 20%;
}
#remoteTrack > div .name {
  position: absolute;
  color: #fff;
  font-weight: 500;
  bottom: 0;
  left: 0;
  padding: 5px 10px;
  width: 100%;
  text-shadow: 1px 1px #000;
}
#domTrack {
  max-width: 100%;
  width: 100%;
  max-height: 90vh;

  flex-basis: 100%;
}
#domTrack > div {
  position: relative;
  max-height: 100%;
  max-height: 90vh;
}
#domTrack > div video {
  width: 100%;
  /* max-height: 100%; */
  object-fit: cover;
  max-height: 90vh;
}
#domTrack > div .name {
  position: absolute;
  color: #fff;
  font-weight: 500;
  bottom: 7px;
  left: 0;
  padding: 5px 10px;
  width: 100%;
  text-shadow: 1px 1px #000;
}
#canvas audio {
  width: 100%;
}
#canvas canvas {
  width: 100%;
  height: 200 !important;
}
#remoteTrack #ekoStream {
  min-height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.muteSvg {
  position: absolute;
  right: 10px;
  bottom: 9px;

  height: 24px;
}
.muteSvg svg {
  background: white;
  border-radius: 25%;
}
.overlay {
  mix-blend-mode: overlay !important;
}
.hidden {
  visibility: hidden !important;
}
.hide {
  display: none !important;
}
.blackBg {
  background: black !important;
}
.videoSvg {
  position: absolute;
  right: 10px;
  top: 9px;
  height: 24px;
}
.videoSvg svg {
  background: white;
  border-radius: 25%;
}
#audioPlay {
  background: white;
  height: 434.5px;
}
.audio-container {
  position: relative;
}
.close-btn {
  right: 0;
  position: absolute;
  top: -21px;
}
.call-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
