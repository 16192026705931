<template>
  <div>
    <div>
      <v-data-table
        :headers="headers"
        :items="examNotes"
        sort-by="date"
        class="elevation-1"
      >
        <template v-slot:item.date="{ item }">
          <div v-html="formatDate(item.date)"></div>
        </template>
        <template v-slot:item.preview="{ item }">
          <div
            v-html="
              trimPreview(item.summary === '' ? item.notes : item.summary)
            "
          ></div>
        </template>
        <template v-slot:item.view="{ item }">
          <v-icon @click="viewItem(item)" color="primary">
            open_in_new
          </v-icon>
        </template>
      </v-data-table>
    </div>
    <v-container>
      <v-bottom-sheet v-model="showNotes" scrollable hide-overlay>
        <v-card v-if="selectedNotes" class="text--black">
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text color="red" @click="showNotes = false" small
              >Close</v-btn
            >

            <v-btn @click="printPdf()" text small color="primary"
              >Save Exam Summary <v-icon right>mdi-download</v-icon></v-btn
            >
          </v-card-actions>
          <v-card-text
            v-if="selectedNotes.summary !== ''"
            id="notesContent"
            class="black--text"
            v-html="selectedNotes.header + selectedNotes.summary"
          >
          </v-card-text>
          <v-card-text
            v-else
            id="notesContent"
            class="black--text"
            v-html="selectedNotes.header + selectedNotes.notes"
          >
          </v-card-text>
        </v-card>
      </v-bottom-sheet>
    </v-container>
  </div>
</template>
<script>
import * as fb from '../../firebase'
import * as moment from 'moment'
import html2pdf from 'html2pdf.js'
export default {
  name: 'PastNotes',
  props: ['patientId', 'practiceId', 'uniqueName'],
  data() {
    return {
      patient: {},
      doctor: {},
      appointment: {},
      examNotes: [],
      notes: [],
      selectedNotes: {},
      showNotes: false,
      headers: [
        { text: 'Date', value: 'date' },
        { text: 'Preview', value: 'preview' },
        { text: 'View', value: 'view' }
      ]
    }
  },
  async mounted() {
    await this.$bind(
      'appointment',
      fb.db.doc(`Practices/${this.practiceId}/Appointments/${this.uniqueName}`)
    )

    await this.$bind(
      'examNotes',
      fb.db.collection(`Patients/${this.appointment.patientId}/Exam_Notes`)
    )
  },
  methods: {
    viewItem(item) {
      this.selectedNotes = { ...item }
      this.showNotes = true
    },
    formatDate(date) {
      return moment(date.toDate()).format('LLLL')
    },
    trimPreview(notes) {
      return `${notes.substring(0, 100)}...`
    },
    printPdf() {
      window.scrollTo(0, 0)
      var element = document.getElementById('notesContent')
      var opt = {
        margin: 1,
        filename: `notes-${moment(this.selectedNotes.date.toDate()).format(
          'MM-DD-YYYY'
        )}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'legal', orientation: 'portrait' }
      }
      html2pdf()
        .set(opt)
        .from(element)
        .save()
    }
  },
  computed: {
    computedDateFormattedMomentjs: {
      get() {
        return this.selectedNotes.date
          ? moment(this.selectedNotes.date.toDate()).format('LLLL')
          : ''
      },
      set() {}
    }
  }
}
</script>
<style>
#patientInfo {
  font-family: Arial, Helvetica, sans-serif;
}
</style>
