<template>
  <div v-if="dataLoaded">
    <v-card-title>
      Exam Notes
      <v-btn
        :disabled="!hasOutline"
        color="primary"
        text
        class="ml-4"
        @click="loadOutline()"
        >Load Exam Outline</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn @click="openPdf()" :loading="loadingPdf" text color="primary"
        >Save Exam Summary <v-icon right>mdi-download</v-icon></v-btn
      ></v-card-title
    >

    <v-card-text>
      <v-card>
        <ckeditor
          :editor="editor"
          v-model="editorData"
          :config="editorConfig"
        ></ckeditor>
      </v-card>
    </v-card-text>
    <v-card-actions class="ma-2">
      <v-btn @click="save()" color="primary" :block="$mq === 'mobile'">
        Save
      </v-btn>
      <v-chip v-if="indicate" color="grey" outlined label class="ml-4">
        <v-icon left>
          mdi-checkbox-marked-outline
        </v-icon>
        Saved
      </v-chip>
    </v-card-actions>
  </div>
</template>
<script>
import * as fb from '../../firebase'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import * as moment from 'moment'
import html2pdf from 'html2pdf.js'
import jszip from 'jszip'
import { saveAs } from 'file-saver'

export default {
  name: 'CallNotes',
  props: ['patientId', 'practiceId', 'uniqueName'],
  data() {
    return {
      indicate: false,
      dataLoaded: false,
      loadingPdf: false,
      editor: ClassicEditor,
      editorData: '<p></p>',
      editorConfig: {
        removePlugins: ['MediaEmbed', 'Table', 'TableToolbar', 'BlockQuote'],
        toolbar: [
          'heading',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'indent',
          'undo',
          'redo'
        ]
      },
      patient: {},
      doctor: {},
      appointment: {},
      examNotes: {},
      medications: [],
      notesHtml: '',
      documentsPdf: '',
      medicationsPdf: '',
      graphsPdf: '',
      hasOutline: false,
      documents: [],
      medicationPhotos: [],
      medicationName: [],
      itemsPerPage: 100,
      medicationsHeader: [
        {
          text: 'Medication Name',
          align: 'start',
          value: 'fileName'
        }
      ],
      allInOne: ''
    }
  },
  async mounted() {
    await this.$bind(
      'appointment',
      fb.db.doc(`Practices/${this.practiceId}/Appointments/${this.uniqueName}`)
    )

    await this.$bind(
      'patient',
      fb.db.doc(`Patients/${this.appointment.patientId}`)
    )

    await this.$bind(
      'doctor',
      fb.db.doc(`Proxies/${this.appointment.doctorId}`)
    ).then(() => {
      if (this.doctor.examOutline) {
        this.hasOutline = true
      }
    })

    await this.$bind(
      'examNotes',
      fb.db.doc(
        `Patients/${this.appointment.patientId}/Exam_Notes/${this.uniqueName}`
      )
    )
    if (this.examNotes) {
      this.editorData = this.examNotes.notes
    }

    const medicalHistory = document.getElementById('medicalHistory')
    var medicalHistoryHtml = ''
    var medicationsTextHtml = ''
    var medicationsImagesHtml = ''
    var documentsHtml = ''
    var symptomsHtml = ''
    var hrchartHtml = ''
    if (typeof medicalHistory !== 'undefined' && medicalHistory !== null) {
      medicalHistoryHtml = medicalHistory.innerHTML
    }
    const symptoms = document.getElementById('symptoms')
    if (typeof symptoms !== 'undefined' && symptoms !== null) {
      symptomsHtml = symptoms.innerHTML
    }

    const medicationsText = document.getElementById('medications-text')
    if (typeof medicationsText !== 'undefined' && medicationsText !== null) {
      medicationsTextHtml = medicationsText.innerHTML
    }

    const medicationsImages = document.getElementById('medications-images')
    if (
      typeof medicationsImages !== 'undefined' &&
      medicationsImages !== null
    ) {
      medicationsImagesHtml = medicationsImages.innerHTML
    }
    console.log(medicationsImagesHtml)

    const documents = document.getElementById('documents')
    if (typeof documents !== 'undefined' && documents !== null) {
      documentsHtml = documents.innerHTML
    }

    const hrchart = document.getElementById('hr-monthly')
    if (typeof hrchart !== 'undefined' && hrchart !== null) {
      hrchartHtml = hrchart.src
    }

    var whrchartHtml = ''
    const whrchart = document.getElementById('whr-monthly')
    if (typeof whrchart !== 'undefined' && whrchart !== null) {
      whrchartHtml = whrchart.src
    }

    var bgchartHtml = ''
    const bgchart = document.getElementById('bg-monthly')
    if (typeof bgchart !== 'undefined' && bgchart !== null) {
      bgchartHtml = bgchart.src
    }

    var poxchartHtml = ''
    const poxchart = document.getElementById('pox-monthly')
    if (typeof poxchart !== 'undefined' && poxchart !== null) {
      poxchartHtml = poxchart.src
    }

    var bpchartHtml = ''
    const bpchart = document.getElementById('bp-monthly')
    if (typeof bpchart !== 'undefined' && bpchart !== null) {
      bpchartHtml = bpchart.src
    }

    var bmichartHtml = ''
    const bmichart = document.getElementById('bmi-monthly')
    if (typeof bmichart !== 'undefined' && bmichart !== null) {
      bmichartHtml = bmichart.src
    }

    var vomchartHtml = ''
    const vomchart = document.getElementById('vom-monthly')
    if (typeof vomchart !== 'undefined' && vomchart !== null) {
      vomchartHtml = vomchart.src
    }

    this.notesHtml = `<style>
#patientInfo table td {
  text-align: left;
}
</style>

<div id="patientInfo">
  <table>
    <tr>
      <td class="name"><strong>Patient Name:</strong> ${this.patient.fname} ${
      this.patient.lname
    }</td>

    </tr>
    <tr>
      <td  class="date"><strong>Visit Date: </strong>${moment(
        this.appointment.startTime.toDate()
      ).format('LLL')}</td>
    </tr>
    <tr><td></td></tr>
     <tr>
      <td><strong>Email: </strong>${
        this.patient.email ? this.patient.email : '--'
      }</td>
    </tr>
    <tr>
      <td><strong>Phone Number:</strong> ${
        this.patient.phoneNumber ? this.patient.phoneNumber : '--'
      }</td>
      <td></td>
            <td><strong>Date of Birth: </strong>${
              this.patient.medicalcard.birthdate
                ? moment(this.patient.medicalcard.birthdate.toDate()).format(
                    'LL'
                  )
                : '--'
            }</td>

    </tr>


  </table>
  <br>

 <table>
    <tr>
      <td><strong>Pulse: </strong>${
        this.appointment.vitals.pulse ? this.appointment.vitals.pulse : '--'
      } bpm</td>
      <td><strong>PulseOx: </strong>${
        this.appointment.vitals.pulseox ? this.appointment.vitals.pulseox : '--'
      } %</td>
      <td><strong>Respiration: </strong>${
        this.appointment.vitals.resprate
          ? this.appointment.vitals.resprate
          : '--'
      } bths/min</td>
    </tr>
    <tr>
      <td><strong>Temperature: </strong>${
        this.appointment.vitals.temperature
          ? this.appointment.vitals.temperature
          : '--'
      } &#176; F </td>
      <td><strong>Pain Scale: </strong>${
        this.appointment.vitals.painscale
          ? this.appointment.vitals.painscale
          : '--'
      }</td>
    </tr>
  </table>
  <br>

   <br>
  <h3><strong>Notes:</strong></h3>
  <br>
  <div class="html2pdf__page-break">
  ${this.editorData}
  </div>

  <br>

  <h3><strong>Symptoms</strong></h3>
  <br>
  <div class="html2pdf__page-break">
  ${symptomsHtml}
  </div>

  <br>

      <h3><strong>Medical History</strong></h3>
    <br>
    <div class="html2pdf__page-break">
    ${medicalHistoryHtml}
    </div>


</div>
`

    this.documentsPdf = `<style>
#patientInfo table td {
  text-align: left;
}
</style>

<div id="patientInfo">
  <table>
    <tr>
      <td class="name"><strong>Patient Name:</strong> ${this.patient.fname} ${
      this.patient.lname
    }</td>

    </tr>
    <tr>
      <td  class="date"><strong>Visit Date: </strong>${moment(
        this.appointment.startTime.toDate()
      ).format('LLL')}</td>
    </tr>
    <tr><td></td></tr>
     <tr>
      <td><strong>Email: </strong>${
        this.patient.email ? this.patient.email : '--'
      }</td>
    </tr>
    <tr>
      <td><strong>Phone Number:</strong> ${
        this.patient.phoneNumber ? this.patient.phoneNumber : '--'
      }</td>
      <td></td>
            <td><strong>Date of Birth: </strong>${
              this.patient.medicalcard.birthdate
                ? moment(this.patient.medicalcard.birthdate.toDate()).format(
                    'LL'
                  )
                : '--'
            }</td>

    </tr>


  </table>
  <br>

  <h3><strong>Medical Documents</strong></h3>
  <br>
  <div class="html2pdf__page-break">
  ${documentsHtml}
  </div>



</div>
`

    this.medicationsPdf = `<style>
#patientInfo table td {
  text-align: left;
}
</style>

<div id="patientInfo">
  <table>
    <tr>
      <td class="name"><strong>Patient Name:</strong> ${this.patient.fname} ${
      this.patient.lname
    }</td>

    </tr>
    <tr>
      <td  class="date"><strong>Visit Date: </strong>${moment(
        this.appointment.startTime.toDate()
      ).format('LLL')}</td>
    </tr>
    <tr><td></td></tr>
     <tr>
      <td><strong>Email: </strong>${
        this.patient.email ? this.patient.email : '--'
      }</td>
    </tr>
    <tr>
      <td><strong>Phone Number:</strong> ${
        this.patient.phoneNumber ? this.patient.phoneNumber : '--'
      }</td>
      <td></td>
            <td><strong>Date of Birth: </strong>${
              this.patient.medicalcard.birthdate
                ? moment(this.patient.medicalcard.birthdate.toDate()).format(
                    'LL'
                  )
                : '--'
            }</td>

    </tr>


  </table>
  <br>

    <h3><strong>Medications</strong></h3>
    <br>
    <div class="html2pdf__page-break">
    ${medicationsImagesHtml}
    ${medicationsTextHtml}
    </div>



</div>
`
    this.graphsPdf = `<style>
#patientInfo table td {
  text-align: left;
}
</style>

<div id="patientInfo">
  <table>
    <tr>
      <td class="name"><strong>Patient Name:</strong> ${this.patient.fname} ${
      this.patient.lname
    }</td>

    </tr>
    <tr>
      <td  class="date"><strong>Visit Date: </strong>${moment(
        this.appointment.startTime.toDate()
      ).format('LLL')}</td>
    </tr>
    <tr><td></td></tr>
     <tr>
      <td><strong>Email: </strong>${
        this.patient.email ? this.patient.email : '--'
      }</td>
    </tr>
    <tr>
      <td><strong>Phone Number:</strong> ${
        this.patient.phoneNumber ? this.patient.phoneNumber : '--'
      }</td>
      <td></td>
            <td><strong>Date of Birth: </strong>${
              this.patient.medicalcard.birthdate
                ? moment(this.patient.medicalcard.birthdate.toDate()).format(
                    'LL'
                  )
                : '--'
            }</td>

    </tr>


  </table>
  <br>

    <h3><strong>Health Data</strong></h3>
    <br>
    <div class="html2pdf__page-break">
    ${
      hrchartHtml !== ''
        ? `<img style="width: 800px;" src="${hrchartHtml}" />`
        : '<div>No Heart Rate Data Provided</div>'
    }
    ${
      whrchartHtml !== ''
        ? `<img style="width: 800px;" src="${whrchartHtml}" />`
        : '<div>No Walking Heart Rate Data Provided</div>'
    }
    

    </div>
    <div class="html2pdf__page-break">
    ${
      bgchartHtml !== ''
        ? `<img style="width: 800px;" src="${bgchartHtml}" />`
        : '<div>No Blood Glucose Data Provided</div>'
    }
    ${
      poxchartHtml !== ''
        ? `<img style="width: 800px;" src="${poxchartHtml}" />`
        : '<div>No Pulse Ox Data Provided</div>'
    }
    

    </div>

    <div class="html2pdf__page-break">
    ${
      bpchartHtml !== ''
        ? `<img style="width: 800px;" src="${bpchartHtml}" />`
        : '<div>No Blood Pressure Data Provided</div>'
    }
    ${
      bmichartHtml !== ''
        ? `<img style="width: 800px;" src="${bmichartHtml}" />`
        : ''
    }
    ${
      vomchartHtml !== ''
        ? `<img style="width: 800px;" src="" />`
        : '<div>No Cardiovascular Fitness Data Provided</div>'
    }

    </div>



</div>`
    this.allInOne =
      this.notesHtml + this.documentsPdf + this.medicationsPdf + this.graphsPdf

    // console.log(this.graphsPdf)
    this.dataLoaded = true
  },
  watch: {
    indicate: function() {
      setTimeout(
        function() {
          this.indicate = false
        }.bind(this),
        2000
      )
    }
  },
  methods: {
    openPdf() {
      let routeData = this.$router.resolve({
        path: `/patientpdf/${this.practiceId}/${this.uniqueName}/${this.patientId}`
      })
      window.open(routeData.href, '_blank')
    },
    loadOutline() {
      this.editorData = `${this.doctor.examOutline} ${this.editorData}`
    },
    async save() {
      try {
        await fb.patientsCollection
          .doc(this.appointment.patientId)
          .collection('Exam_Notes')
          .doc(this.uniqueName)
          .set(
            {
              notes: `${this.editorData}`,
              summary: `${this.notesHtml}`,
              header: `<strong>Patient Name:</strong> ${this.patient.fname} ${
                this.patient.lname
              }<br/><strong>Doctor:</strong> Dr. ${this.doctor.fname} ${
                this.doctor.lname
              }<br/><strong>Date:</strong> ${moment(
                this.appointment.startTime.toDate()
              ).format('LLLL')}<p></p>`,
              updated: fb.db.app.firebase_.firestore.Timestamp.fromDate(
                new Date()
              ).toDate(),
              date: this.appointment.startTime
            },
            { merge: true }
          )
        this.indicate = true
      } catch (error) {
        this.error = error.message
        console.log(error)
      }
    },
    async printPdfS() {
      this.loadingPdf = true
      window.scrollTo(0, document.body.scrollHeight)
      window.scrollTo(0, 0)
      var opt = {
        margin: 1,
        filename: `${this.patient.fname}-${
          this.patient.lname
        }-${moment().format('MM-DD-YYYY')}.pdf`,
        image: { type: 'jpeg', quality: 0.95 },
        html2canvas: {
          dpi: 96,
          letterRendering: true,
          useCORS: true,
          scale: 2
        },
        jsPDF: { unit: 'in', format: 'legal', orientation: 'portrait' }
      }

      let file1 = await html2pdf()
        .set(opt)
        .from(this.notesHtml)
        .toPdf()
        .output('blob')
        .then(data => {
          return data
        })

      // html2pdf()
      //   .set(opt)
      //   .from(this.graphsPdf)
      //   .toPdf()
      //   .save()

      let file2 = await html2pdf()
        .set(opt)
        .from(this.documentsPdf)
        .toPdf()
        .output('blob')
        .then(data => {
          return data
        })

      let file3 = await html2pdf()
        .set(opt)
        .from(this.medicationsPdf)
        .toPdf()
        .output('blob')
        .then(data => {
          return data
        })

      let file4 = await html2pdf()
        .set(opt)
        .from(this.graphsPdf)
        .toPdf()
        .output('blob')
        .then(data => {
          return data
        })

      console.log(file1, file2, file3, file4)
      this.saveZip(file1, file2, file3, file4)

      this.loadingPdf = false
    },
    async printPdf() {
      this.loadingPdf = true
      window.scrollTo(0, document.body.scrollHeight)
      window.scrollTo(0, 0)
      var opt = {
        margin: 1,
        filename: `${this.patient.fname}-${
          this.patient.lname
        }-${moment().format('MM-DD-YYYY')}.pdf`,
        image: { type: 'jpeg', quality: 0.95 },
        html2canvas: {
          dpi: 96,
          letterRendering: true,
          useCORS: true,
          scale: 2
        },
        jsPDF: { unit: 'in', format: 'legal', orientation: 'portrait' }
      }

      let file1 = await html2pdf()
        .set(opt)
        .from(this.allInOne)
        .toPdf()
        .output('blob')
        .then(data => {
          return data
        })

      this.saveZipOne(file1)

      this.loadingPdf = false
    },
    saveZipOne(file1) {
      var zip = new jszip()

      var patientFolder = zip.folder(
        `${this.patient.fname}-${this.patient.lname}-${moment().format(
          'MM-DD-YYYY'
        )}`
      )

      patientFolder.file('summary.pdf', file1)

      zip.generateAsync({ type: 'blob' }).then(content => {
        saveAs(
          content,
          `${this.patient.fname}-${this.patient.lname}-${moment().format(
            'MM-DD-YYYY'
          )}.zip`
        )
      })
    },
    saveZip(file1, file2, file3, file4) {
      var zip = new jszip()

      var patientFolder = zip.folder(
        `${this.patient.fname}-${this.patient.lname}-${moment().format(
          'MM-DD-YYYY'
        )}`
      )

      patientFolder.file('summary.pdf', file1)
      patientFolder.file('documents.pdf', file2)
      patientFolder.file('medications.pdf', file3)
      patientFolder.file('healthdata.pdf', file4)

      zip.generateAsync({ type: 'blob' }).then(content => {
        saveAs(
          content,
          `${this.patient.fname}-${this.patient.lname}-${moment().format(
            'MM-DD-YYYY'
          )}.zip`
        )
      })
    }
  }
}
</script>
<style>
#patientInfo table td {
  text-align: left;
}
#patientInfo {
  font-family: Arial, Helvetica, sans-serif;
}
.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  color: #000000;
}
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
  color: #000000;
}
</style>
