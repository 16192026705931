<template>
  <div style="position: relative;">
    <canvas id="heart-rate-chart" ref="heartRateChart" width="100%"> </canvas>
    <slot></slot>
  </div>
</template>

<script>
import * as cjs from 'chart.js'
import { registerables } from 'chart.js'
import 'chartjs-adapter-moment'

export default {
  name: 'Daily',
  props: ['data'],
  data() {
    return {
      healthData: [],
      filteredData: [],
      chartData: [],
      myChart: null,
      loading: true
    }
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        this.loading = true
        this.chartData = this.data
      }
    },
    chartData: function() {
      this.loading = true
      this.fillData()
    },
    loading: function() {
      this.$emit('graphLoading', this.loading)
    }
  },
  created() {
    cjs.Chart.register(...registerables)
  },
  mounted() {
    this.loading = true
    this.fillData()
  },
  methods: {
    fillData() {
      this.loading = false
      if (this.myChart) {
        this.myChart.destroy()
      }
      this.myChart = new cjs.Chart(this.$refs.heartRateChart, {
        type: 'line',
        data: {
          datasets: [
            {
              label: 'Pulse Ox',
              borderColor: 'red',
              backgroundColor: 'red',
              data: this.chartData
            }
          ]
        },
        options: {
          plugins: {
            tooltip: {
              callbacks: {
                label: context => {
                  var label = ''

                  if (label) {
                    label += ': '
                  }
                  label += context.raw.date
                  if (context.parsed.y !== null) {
                    label += ' : ' + context.parsed.y
                  }

                  return label
                }
              }
            }
          },
          scales: {
            xAxes: {
              type: 'time',
              time: { unit: 'hour' }
            },
            yAxes: {
              display: true,
              ticks: {
                beginAtZero: false,
                stepSize: 0.1
              },
              scaleLabel: {
                display: true,
                labelString: 'Y axis label'
              },
              suggestedMax: 1,
              suggestedMin: 0
            }
          }
        }
      })
    }
  }
}
</script>
