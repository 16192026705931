<template>
  <div>
    <!-- <Yearly :chartData="chartData" /> -->
    <div>
      <v-card flat v-show="selected === 'DAY'">
        <v-card-title class="pt-0"
          >Blood Pressure
          <v-spacer></v-spacer>
          <v-card-subtitle
            class="mt-0 pt-0 black--text font-weight-bold"
            style="text-align: right;"
          >
            {{ dayCount }} / 30 Days
          </v-card-subtitle>
        </v-card-title>
        <v-card-subtitle class="mt-0 pt-0 black--text font-weight-bold"
          >Date: {{ computedToday }}</v-card-subtitle
        >
        <v-card-text>
          <Daily :data="dailySystolicData" :dataTwo="dailyDiastolicData">
            <template
              v-if="
                (dailySystolicData.length <= 0 &&
                  dailyDiastolicData.length <= 0) ||
                  loadingGraph
              "
            >
              <v-overlay opacity="0" absolute :value="true">
                <v-card-text
                  v-if="
                    dailySystolicData.length <= 0 &&
                      dailyDiastolicData.length <= 0 &&
                      !loadingGraph
                  "
                  class="black--text"
                  >No Data</v-card-text
                >
              </v-overlay>
            </template>
          </Daily>
        </v-card-text>
      </v-card>

      <v-card flat v-show="selected === 'MONTH'">
        <v-card-title class="pt-0"
          >Blood Pressure
          <v-spacer></v-spacer>
          <v-card-subtitle
            class="mt-0 pt-0 black--text font-weight-bold"
            style="text-align: right;"
          >
            {{ dayCount }} / 30 Days
          </v-card-subtitle>
        </v-card-title>
        <v-card-subtitle class="mt-0 pt-0 black--text font-weight-bold"
          >Month: {{ computedMonth }}</v-card-subtitle
        >
        <v-card-text>
          <Monthly :data="monthlySystolicData" :dataTwo="monthlyDiastolicData">
            <template
              v-if="
                (monthlySystolicData.length <= 0 &&
                  monthlyDiastolicData.length <= 0) ||
                  loadingGraph
              "
            >
              <v-overlay opacity="0" absolute :value="true">
                <v-card-text
                  v-if="
                    monthlySystolicData.length <= 0 &&
                      monthlyDiastolicData.length <= 0 &&
                      !loadingGraph
                  "
                  class="black--text"
                  >No Data</v-card-text
                >
              </v-overlay>
            </template>
          </Monthly>
        </v-card-text>
      </v-card>

      <v-card flat v-show="selected === 'WEEK'">
        <v-card-title class="pt-0"
          >Blood Pressure
          <v-spacer></v-spacer>
          <v-card-subtitle
            class="mt-0 pt-0 black--text font-weight-bold"
            style="text-align: right;"
          >
            {{ dayCount }} / 30 Days
          </v-card-subtitle>
        </v-card-title>
        <v-card-subtitle class="mt-0 pt-0 black--text font-weight-bold"
          >Week Of: {{ computedWeek }}</v-card-subtitle
        >
        <v-card-text>
          <Weekly :data="weeklySystolicData" :dataTwo="weeklyDiastolicData">
            <template
              v-if="
                (weeklySystolicData.length <= 0 &&
                  weeklyDiastolicData.length <= 0) ||
                  loadingGraph
              "
            >
              <v-overlay opacity="0" absolute :value="true">
                <v-card-text
                  v-if="
                    weeklySystolicData.length <= 0 &&
                      weeklyDiastolicData.length <= 0 &&
                      !loadingGraph
                  "
                  class="black--text"
                  >No Data</v-card-text
                >
              </v-overlay>
            </template>
          </Weekly>
        </v-card-text>
      </v-card>
      <!-- 
      <v-card flat v-show="selected === 'YEAR'">
        <v-card-title class="pt-0"
          >Blood Pressure
          <v-spacer></v-spacer>
          <v-card-subtitle
            class="mt-0 pt-0 black--text font-weight-bold"
            style="text-align: right;"
          >
            {{ dayCount }} / 30 Days
          </v-card-subtitle>
        </v-card-title>
        <v-card-subtitle class="mt-0 pt-0 black--text font-weight-bold"
          >Year: {{ computedYear }}</v-card-subtitle
        >
        <v-card-text>
          <Yearly :data="yearlySystolicData" :dataTwo="yearlyDiastolicData">
            <template
              v-if="
                (yearlySystolicData.length <= 0 &&
                  yearlyDiastolicData.length <= 0) ||
                  loadingGraph
              "
            >
              <v-overlay opacity="0" absolute :value="true">
                <v-card-text
                  v-if="
                    yearlySystolicData.length <= 0 &&
                      yearlyDiastolicData.length <= 0 &&
                      !loadingGraph
                  "
                  class="black--text"
                  >No Data</v-card-text
                >
              </v-overlay>
            </template>
          </Yearly>
        </v-card-text>
      </v-card> -->

      <v-overlay
        v-if="loadingGraph"
        class="d-flex justify-center align-center"
        opacity="0.4"
        absolute
        :value="true"
      >
        <v-progress-circular indeterminate color="white"></v-progress-circular>
        <span class="ml-4 white--text">Loading Data</span>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Daily from './Daily'
// import Yearly from './Yearly'
import Monthly from './Monthly'
import Weekly from './Weekly'

export default {
  name: 'BloodPressureMain',
  props: ['patientId', 'uniqueName', 'practiceId', 'selectedDisplay', 'data'],
  components: {
    // Yearly,
    Monthly,
    Daily,
    Weekly
  },
  data() {
    return {
      loadingGraph: false,
      today: new Date(),
      selected: 'MONTH',
      healthData: [],
      healthDataTwo: [],
      dailySystolicData: [],
      dailyDiastolicData: [],
      monthlySystolicData: [],
      monthlyDiastolicData: [],
      weeklySystolicData: [],
      weeklyDiastoliData: [],
      yearlySystolicData: [],
      yearlyDiastolicData: [],
      dayCount: 0
    }
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        if (this.data) {
          const data = this.data.filter(
            x => x.type == 'BLOOD_PRESSURE_SYSTOLIC'
          )
          this.healthData = data
        }
      }
    },
    dataTwo: {
      immediate: true,
      handler() {
        if (this.data) {
          const dataTwo = this.data.filter(
            x => x.type == 'BLOOD_PRESSURE_DIASTOLIC'
          )
          this.healthDataTwo = dataTwo
        }
      }
    },
    selectedDisplay: {
      immediate: true,
      handler() {
        if (this.selectedDisplay === 'MONTH') {
          this.selected = 'MONTH'
        } else if (this.selectedDisplay === 'WEEK') {
          this.selected = 'WEEK'
        } else if (this.selectedDisplay === 'DAY') {
          this.selected = 'DAY'
        } else if (this.selectedDisplay === 'YEAR') {
          this.selected = 'YEAR'
        }
      }
    },
    healthData: {
      immediate: true,
      handler() {
        this.getDailySystolicData()
        this.getWeeklySystolicData()
        this.getMonthlyData()
        this.getYearlyData()
      }
    },
    healthDataTwo: {
      immediate: true,
      handler() {
        this.getDailySystolicData()
        this.getWeeklySystolicData()
        this.getMonthlyData()
        this.getYearlyData()
      }
    }
  },
  // async mounted() {
  //   this.loadingGraph = true
  //   this.$bind(
  //     'dataTwo',
  //     await fb.db
  //       .collection(`Patients/${this.patientId}/HealthData`)
  //       .where('type', '==', 'BLOOD_PRESSURE_DIASTOLIC')
  //   )

  //   this.$bind(
  //     'data',
  //     await fb.db
  //       .collection(`Patients/${this.patientId}/HealthData`)
  //       .where('type', '==', 'BLOOD_PRESSURE_SYSTOLIC')
  //     // .where('type', '==', 'BLOOD_PRESSURE_DIASTOLIC')
  //   ).then(() => {
  //     this.loadingGraph = false
  //   })
  // },
  methods: {
    getDailySystolicData() {
      let dailySystolicData = []
      let dailyDiastolicData = []
      const filteredDailySystolicData = this.healthData.filter(x =>
        moment(x.date.toDate()).isSame(
          moment(this.today).subtract(1, 'day'),
          'day'
        )
      )
      for (const item of filteredDailySystolicData) {
        const date = moment(item.date.toDate())
        const dateU = moment(item.date.toDate()).format('lll')

        dailySystolicData.push({ x: date, y: item.value, date: dateU })
      }
      const filteredDailyDiastolicData = this.healthDataTwo.filter(x =>
        moment(x.date.toDate()).isSame(
          moment(this.today).subtract(2, 'day'),
          'day'
        )
      )
      for (const item of filteredDailyDiastolicData) {
        const date = moment(item.date.toDate())
        const dateU = moment(item.date.toDate()).format('lll')
        dailyDiastolicData.push({ x: date, y: item.value, date: dateU })
      }
      this.dailySystolicData = dailySystolicData
      this.dailyDiastolicData = dailyDiastolicData
    },
    getWeeklySystolicData() {
      let weeklySystolicData = []
      let weeklyDiastolicData = []

      const filteredWeeklySystolicData = this.healthData.filter(x =>
        moment(x.date.toDate()).isBetween(
          moment(this.today).subtract(7, 'days'),
          moment(this.today),
          'day'
        )
      )
      for (const item of filteredWeeklySystolicData) {
        const date = moment(item.date.toDate())
        const dateU = moment(item.date.toDate()).format('lll')
        weeklySystolicData.push({ x: date, y: item.value, date: dateU })
      }
      const filteredWeeklyDiastolicData = this.healthDataTwo.filter(x =>
        moment(x.date.toDate()).isBetween(
          moment(this.today).subtract(7, 'days'),
          moment(this.today),
          'day'
        )
      )
      for (const item of filteredWeeklyDiastolicData) {
        const date = moment(item.date.toDate())
        const dateU = moment(item.date.toDate()).format('lll')
        weeklyDiastolicData.push({ x: date, y: item.value, date: dateU })
      }
      this.weeklySystolicData = weeklySystolicData
      this.weeklyDiastolicData = weeklyDiastolicData
    },
    getMonthlyData() {
      let monthlySystolicData = []
      let monthlyDiastolicData = []

      for (const item of this.healthData) {
        const date = moment(item.date.toDate())
        const dateU = moment(item.date.toDate()).format('lll')

        monthlySystolicData.push({ x: date, y: item.value, date: dateU })
      }
      for (const item of this.healthDataTwo) {
        const date = moment(item.date.toDate())
        const dateU = moment(item.date.toDate()).format('lll')

        monthlyDiastolicData.push({ x: date, y: item.value, date: dateU })
      }
      this.calculateTotalData(monthlySystolicData)

      this.monthlySystolicData = monthlySystolicData
      this.monthlyDiastolicData = monthlyDiastolicData
    },
    getYearlyData() {
      let yearlySystolicData = []
      let yearlyDiastolicData = []

      const filteredSystolicYearlyData = this.healthData.filter(x =>
        moment(x.date.toDate()).isBetween(
          moment(new Date()).startOf('year'),
          moment(new Date()).endOf('year')
        )
      )
      for (const item of filteredSystolicYearlyData) {
        const date = moment(item.date.toDate())
        const dateU = moment(item.date.toDate()).format('lll')
        yearlySystolicData.push({ x: date, y: item.value, date: dateU })
      }
      const filteredDiastolicYearlyData = this.healthDataTwo.filter(x =>
        moment(x.date.toDate()).isBetween(
          moment(new Date()).startOf('year'),
          moment(new Date()).endOf('year')
        )
      )
      for (const item of filteredDiastolicYearlyData) {
        const date = moment(item.date.toDate())
        const dateU = moment(item.date.toDate()).format('lll')
        yearlyDiastolicData.push({ x: date, y: item.value, date: dateU })
      }
      this.yearlySystolicData = yearlySystolicData
      this.yearlyDiastolicData = yearlyDiastolicData
    },
    calculateTotalData(monthlyData) {
      let dayCount = 0
      let last = moment().day()
      monthlyData.sort((a, b) => (a.x > b.x ? 1 : -1))
      for (const item of monthlyData) {
        if (moment(item.x).day() !== last) {
          dayCount++
        }
        last = moment(item.x).day()
      }
      this.dayCount = dayCount
    }
  },
  computed: {
    computedToday: {
      get() {
        return this.today ? moment(this.today).format('MMMM D, YYYY') : ''
      },
      set() {}
    },
    computedMonth: {
      get() {
        return this.today ? moment(this.today).format('MMMM, YYYY') : ''
      },
      set() {}
    },
    computedWeek: {
      get() {
        return this.today ? moment(this.today).format('MMMM DD, YYYY') : ''
      },
      set() {}
    },
    computedYear: {
      get() {
        return this.today ? moment(this.today).format('YYYY') : ''
      },
      set() {}
    }
  }
}
</script>
