<template>
  <div>
    <v-card rounded="lg" class="mt-4">
      <v-card-title>Virtual Physical Exam</v-card-title>
      <v-card-text v-if="allRecordings.length > 0 || videos.length > 0">
        <v-row>
          <v-col cols="12" md="4">
            <img
              style="max-width: 100%; width: 100%; height: 700px; object-fit: contain;"
              :src="hoverImage"
            />
          </v-col>
          <v-col cols="12" md="8">
            <v-data-table
              dense
              :headers="headersRecordings"
              :items="allRecordings"
              item-key="index"
              class="elevation-0"
              :items-per-page="itemsPerPage"
              hide-default-footer
            >
              <template v-slot:item="{ item }">
                <tr
                  @mouseover="
                    hoverImage = item.primaryImage
                      ? item.primaryImage
                      : 'https://via.placeholder.com/200x300?text=No+Image+Found'
                  "
                >
                  <td style="cursor: pointer;" @click="viewItem(item.index)">
                    <div>
                      <v-icon v-if="item.type === 'video'">
                        ondemand_video
                      </v-icon>
                      <v-icon v-else>
                        volume_up
                      </v-icon>
                    </div>
                  </td>
                  <td style="cursor: pointer;" @click="viewItem(item.index)">
                    {{ item.fileName }}
                  </td>
                  <td>
                    <div>
                      <v-btn
                        x-small
                        text
                        @click="setDownload(item)"
                        color="primary"
                      >
                        download<v-icon right>
                          mdi-download
                        </v-icon></v-btn
                      >
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>No recordings</v-card-text>
    </v-card>

    <v-dialog v-model="downloadMessage" max-width="400px">
      <v-card>
        <v-card-title
          >HIPPA Notice
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="downloadMessage = false">Close</v-btn>
        </v-card-title>
        <v-card-text>
          Downloading of patient medical information is only intended for use by
          health care providers to facilitate care of patients under their
          supervision. Downloading patient medical information is only
          recommended on hospital or medical group computer systems that meet
          HIPAA compliance standards. The health care provider/ user downloading
          patient medical information is solely responsible for meeting HIPAA
          compliance requirements of all downloaded patient medical
          information/data
          <v-btn
            class="mt-4"
            block
            text
            @click.stop="download(selectedDownload)"
            color="primary"
          >
            Download
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="carouselDialog"
      v-model="carouselDialog"
      hide-overlay
      fullscreen
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Virtual Physical Exam</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="notes()">
              Notes
            </v-btn>
            <v-btn dark text @click="close()">
              Close
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-container>
          <v-tabs
            class="justify-center d-flex"
            v-model="model"
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows
            center-active
            @change="setType()"
            :value="model"
          >
            <v-tab v-for="item of allRecordings" :key="item.index">
              <v-icon
                left
                v-html="item.type === 'audio' ? 'volume_up' : 'ondemand_video'"
              ></v-icon>
              {{ item.fileName }}
            </v-tab>
          </v-tabs>
        </v-container>
        <div :id="`playerContainer`">
          <!-- <v-card-title
            v-if="inFullscreen"
            class="white--text component-title"
            >{{ selectedDocument.fileName }}</v-card-title
          > -->
          <v-container v-if="!inFullscreen">
            <v-row class="mt-4 d-flex align-center">
              <v-spacer></v-spacer>
              <v-col>
                <v-btn
                  color="primary"
                  block
                  :class="inFullscreen ? '' : 'white--text'"
                  :disabled="model <= 0"
                  @click="prev()"
                  :dark="inFullscreen ? true : false"
                >
                  <v-icon left>arrow_back</v-icon> Prev</v-btn
                >
              </v-col>
              <v-col>
                <v-menu v-if="!inFullscreen" offset-y>
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn
                      block
                      :class="inFullscreen ? '' : 'white--text'"
                      color="primary"
                      :disabled="allRecordings.length <= 1"
                      :dark="inFullscreen ? true : false"
                      v-on="on"
                      v-bind="attrs"
                      >Jump <v-icon right>mdi-debug-step-over</v-icon></v-btn
                    >
                  </template>
                  <v-list>
                    <v-list-item
                      @change="model = item.index"
                      v-for="item in allRecordings"
                      :key="item.id"
                      link
                    >
                      <v-list-item-title
                        v-text="item.fileName"
                      ></v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>

              <v-col>
                <v-btn
                  block
                  :class="inFullscreen ? '' : 'white--text'"
                  :dark="inFullscreen ? true : false"
                  color="primary"
                  :disabled="model === allRecordings.length - 1"
                  @click="next()"
                  >Next <v-icon right>arrow_forward</v-icon></v-btn
                >
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-container>
          <div v-if="inFullscreen" class="title-container">
            <v-card-title
              v-if="inFullscreen"
              class="white--text component-title"
              >{{ selectedDocument.fileName }}</v-card-title
            >
          </div>

          <div
            v-if="inFullscreen"
            class="playerToggle"
            style="height: 100%; top: 0"
          >
            <v-btn
              block
              color="primary"
              class="mb-2 mt-2"
              :disabled="model <= 0"
              @click="prev()"
              dark
            >
              <v-icon>arrow_back</v-icon></v-btn
            >

            <div>
              <v-btn
                block
                color="primary"
                :disabled="allRecordings.length <= 1"
                dark
                class="mb-2"
                @click="jumpList = !jumpList"
                ><v-icon>mdi-debug-step-over</v-icon></v-btn
              >
              <v-list
                v-if="jumpList"
                style="position: absolute; z-index: 9; border-radius: 4px; top: 8%;"
              >
                <v-list-item
                  @click="jumpTo(item.index)"
                  v-for="item in allRecordings"
                  :key="item.id"
                  link
                >
                  <v-list-item-title v-text="item.fileName"></v-list-item-title>
                </v-list-item>
              </v-list>
            </div>

            <v-btn
              dark
              block
              class="mb-2"
              color="primary"
              :disabled="model === allRecordings.length - 1"
              @click="next()"
              ><v-icon>arrow_forward</v-icon></v-btn
            >
            <v-btn
              block
              color="primary"
              class="mb-2"
              dark
              @click="showNotes2 = !showNotes2"
            >
              <v-icon>mdi-notebook-edit</v-icon>
            </v-btn>
          </div>

          <div
            :key="selectedDocument.id"
            :class="!inFullscreen ? 'd-flex justify-center' : ''"
            style="width: 100%; height: 100%;"
          >
            <div
              v-if="selectedDocument.type === 'audio'"
              :style="
                !inFullscreen ? 'width: 85%;' : 'height: 100%; width: 100%;'
              "
            >
              <div class="text--primary">
                <!-- {{ selectedDocument.fileName }} -->

                <v-row
                  style="max-height: 100vh;"
                  :class="inFullscreen ? 'mt-13' : ''"
                >
                  <v-col v-if="showNotes2" :class="inFullscreen ? 'ml-16' : ''">
                    <v-sheet
                      style="background: white;"
                      class="text-center"
                      height="100vh"
                    >
                      <v-card color="#fff" flat>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="red"
                            @click="showNotes2 = false"
                            small
                            >Close</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                        <CallNotes
                          :patientId="patientId"
                          :uniqueName="uniqueName"
                          :practiceId="practiceId"
                        />
                      </v-card>
                    </v-sheet>
                  </v-col>
                  <v-col
                    :style="
                      inFullscreen
                        ? 'background: white; height: 100%; margin-top: 12px; '
                        : ''
                    "
                    :class="inFullscreen && !showNotes2 ? 'ml-18' : ''"
                    :key="selectedDocument.id"
                  >
                    <Waveplayer
                      v-if="selectedDocument"
                      :selectedDocument="selectedDocument"
                      style="width: 100%;"
                    />
                  </v-col>
                </v-row>
                <v-divider light class="mb-2 mt-2"></v-divider>
                <v-btn
                  v-if="!inFullscreen"
                  @click="downloadMessageCarousel = true"
                  small
                  block
                  dark
                  color="primary"
                >
                  download
                  <v-icon right>download</v-icon>
                </v-btn>
              </div>
            </div>

            <div
              :key="selectedDocument.id"
              v-else-if="selectedDocument.type === 'video'"
            >
              <div class="text--primary">
                <!-- {{ selectedDocument.fileName }} -->

                <div v-if="selectedDocument.fileName">
                  <v-row style="max-height: 100vh;">
                    <v-col
                      v-if="showNotes2"
                      :class="inFullscreen ? 'ml-16 mt-13' : ''"
                    >
                      <v-sheet
                        style="background: white;"
                        class="text-center"
                        height="100vh"
                      >
                        <v-card color="#fff" flat>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="red"
                              @click="showNotes2 = false"
                              small
                              >Close</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                          <CallNotes
                            :patientId="patientId"
                            :uniqueName="uniqueName"
                            :practiceId="practiceId"
                          />
                        </v-card>
                      </v-sheet>
                    </v-col>
                    <v-col
                      :key="selectedDocument.id"
                      :class="inFullscreen ? 'mt-13' : ''"
                    >
                      <VuePlyr
                        id="vuePlayer"
                        v-show="model === selectedDocument.index"
                        style="max-height: 95vh; "
                        :options="plyrOptions"
                      >
                        <video controls autoplay autopause loop download>
                          <source :src="selectedDocument.url" />
                        </video>
                      </VuePlyr>
                      <VuePlyr
                        id="vuePlayer"
                        class="nextVideo"
                        v-if="nextDocument && nextDocument.type !== 'audio'"
                        v-show="
                          model === nextDocument.index &&
                            nextDocument &&
                            nextDocument.url
                        "
                        :options="plyrOptions"
                      >
                        <video controls autoplay autopause loop download>
                          <source :src="nextDocument.url" />
                        </video>
                      </VuePlyr>
                    </v-col>
                  </v-row>
                </div>
                <v-divider light class="mb-2"></v-divider>
                <v-btn
                  v-if="!inFullscreen"
                  @click="downloadMessageCarousel = true"
                  small
                  block
                  dark
                  color="primary"
                >
                  download
                  <v-icon right>download</v-icon>
                </v-btn>
              </div>
            </div>

            <div v-else>No Preveiw</div>
          </div>
        </div>

        <v-dialog v-model="downloadMessageCarousel" max-width="400px">
          <v-card>
            <v-card-title
              >HIPPA Notice
              <v-spacer></v-spacer>
              <v-btn text color="red" @click="downloadMessageCarousel = false"
                >Close</v-btn
              >
            </v-card-title>
            <v-card-text>
              Downloading of patient medical information is only intended for
              use by health care providers to facilitate care of patients under
              their supervision. Downloading patient medical information is only
              recommended on hospital or medical group computer systems that
              meet HIPAA compliance standards. The health care provider/ user
              downloading patient medical information is solely responsible for
              meeting HIPAA compliance requirements of all downloaded patient
              medical information/data
              <v-btn
                class="mt-4"
                block
                text
                @click="download(selectedDocument)"
                color="primary"
              >
                Download
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-container>
          <v-bottom-sheet v-model="showNotes" hide-overlay>
            <v-sheet class="text-center" height="auto">
              <v-card>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text color="red" @click="showNotes = false" small
                    >Close</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
                <CallNotes
                  :patientId="patientId"
                  :uniqueName="uniqueName"
                  :practiceId="practiceId"
                />
              </v-card>
            </v-sheet>
          </v-bottom-sheet>
        </v-container>
      </v-card>
    </v-dialog>
    <div id="tempElement"></div>
  </div>
</template>
<script>
import * as fb from '@/firebase'
import CallNotes from '@/components/room/CallNotes'
import Waveplayer from './Waveplayer'
import { eventBus } from '@/main.js'
import { logFileViewed } from '@/Events'
import Axios from 'axios'

export default {
  name: 'Recordings',
  components: {
    CallNotes,
    Waveplayer
  },
  props: ['patientId', 'uniqueName', 'practiceId'],
  data() {
    return {
      model: 0,
      plyrOptions: {
        controls: [
          'play-large',
          'play',
          'progress',
          'current-time',
          'mute',
          'volume',
          'captions',
          'settings',
          'pip',
          'airplay'
        ]
      },
      showExpand: true,
      downloadMessage: false,
      downloadMessageCarousel: false,
      selectedDownload: {},
      showNotes: false,
      showNotes2: false,
      carouselDialog: false,
      itemsPerPage: 100,
      selectedDocument: {},
      nextDocument: {},
      fullScreenElement: null,
      inFullscreen: false,
      jumpList: false,
      headersRecordings: [
        {
          text: 'Type',
          align: 'start',
          value: 'type'
        },
        { text: 'File Name', value: 'fileName' },
        { text: '', value: 'index' }
      ],
      allRecordings: [],
      recordings: [],
      videos: [],
      hoverImage:
        'https://firebasestorage.googleapis.com/v0/b/vpexam-hhvi-dhp.appspot.com/o/Assets%2FdefaultModel.png?alt=media&token=e64043f2-1a3a-4a0b-815e-0e457eb22511'
    }
  },
  watch: {
    model: function() {
      this.setType()
    },
    recordings: function() {
      this.allRecordings = []
      let count = 0

      for (const item of this.recordings) {
        this.allRecordings.push({
          ...item,
          id: item.id,
          index: count,
          type: 'audio'
        })
        count++
      }

      for (const item of this.videos) {
        this.allRecordings.push({
          ...item,
          id: item.id,
          index: count,
          type: 'video'
        })
        count++
      }
      this.allRecordings.sort((a, b) => (a.order > b.order ? 1 : -1))
      let count2 = 0
      for (const item of this.allRecordings) {
        item.index = count2
        count2++
      }
    },
    videos: function() {
      this.allRecordings = []
      let count = 0

      for (const item of this.recordings) {
        this.allRecordings.push({
          ...item,
          id: item.id,
          index: count,
          type: 'audio'
        })
        count++
      }

      for (const item of this.videos) {
        this.allRecordings.push({
          ...item,
          id: item.id,
          index: count,
          type: 'video'
        })
        count++
      }

      this.allRecordings.sort((a, b) => (a.order > b.order ? 1 : -1))
      let count2 = 0
      for (const item of this.allRecordings) {
        item.index = count2
        count2++
      }
    }
  },
  created() {
    document.addEventListener('fullscreenchange', this.exitHandler)
    document.addEventListener('webkitfullscreenchange', this.exitHandler)
    document.addEventListener('mozfullscreenchange', this.exitHandler)
    document.addEventListener('MSFullscreenChange', this.exitHandler)

    this.fullScreenElement = document.getElementById(`playerContainer`)
  },
  updated() {
    this.setPlayerControls()
    this.fullScreenElement = document.getElementById(`playerContainer`)

    const nextVideo = document.querySelector('.nextVideo')
    if (typeof nextVideo !== 'undefined' && nextVideo !== null) {
      nextVideo
        .closest('.plyr__video-wrapper')
        .closest('.plyr.plyr--html5').style.display = 'none'
    }
  },
  async mounted() {
    await this.$bind(
      'recordings',
      fb.db.collection(
        `Practices/${this.practiceId}/Appointments/${this.uniqueName}/Recordings`
      )
    )

    await this.$bind(
      'videos',
      fb.db.collection(
        `Practices/${this.practiceId}/Appointments/${this.uniqueName}/Video Components`
      )
    )

    this.$emit('ready')
  },
  methods: {
    prev() {
      this.model = this.model - 1
    },
    next() {
      this.model = this.model + 1
    },
    jumpTo(index) {
      this.model = index
      this.jumpList = false
    },
    setType() {
      this.selectedDocument = this.allRecordings[this.model]
      if (this.model + 1 <= this.allRecordings.length) {
        this.nextDocument = this.allRecordings[this.model + 1]
      } else {
        this.nextDocument = null
      }

      logFileViewed(
        fb.auth.currentUser.uid,
        this.practiceId,
        this.selectedDocument.id
      )
      eventBus.$emit('stopAudio')
    },
    viewItem(index) {
      this.model = index
      this.selectedDocument = this.allRecordings[this.model]
      if (this.model + 1 <= this.allRecordings.length) {
        this.nextDocument = this.allRecordings[this.model + 1]
      } else {
        this.nextDocument = null
      }
      logFileViewed(
        fb.auth.currentUser.uid,
        this.practiceId,
        this.selectedDocument.id
      )
      this.carouselDialog = true
    },
    notes() {
      this.showNotes = !this.showNotes
    },
    close() {
      this.showNotes = false
      this.carouselDialog = false
    },
    setDownload(item) {
      this.selectedDownload = item
      this.downloadMessage = true
    },
    download(item) {
      var ext = ''
      if (item.type === 'audio') {
        // let mime = 'audio/wav'
        ext = '.wav'
      } else if (item.type === 'video') {
        // let mime = 'video/mpeg'
        ext = '.mp4'
      }

      Axios.get(item.url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data])
          const link = document.createElement('a')
          link.id = 'downloadAnchor'
          link.href = URL.createObjectURL(blob)
          link.download = `${item.fileName}.${ext}`
          link.click()
          URL.revokeObjectURL(link.href)
          link.remove()
          this.downloadMessage = false
          this.downloadMessageCarousel = false
        })
        .catch(error => {
          console.log(error)
        })
    },
    exitHandler() {
      if (
        !document.fullscreenElement &&
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        if (
          typeof document.querySelector('.playerToggle') !== 'undefined' &&
          document.querySelector('.playerToggle') !== null
        ) {
          document
            .querySelector('.playerToggle')
            .classList.remove('showPlayerToggle')
        }
        this.showExpand = true
        this.showNotes2 = false
        this.inFullscreen = false
      }
    },
    togglePlayerFullScreen() {
      if (
        this.fullScreenElement.fullscreenElement ||
        this.fullScreenElement.webkitFullscreenElement ||
        this.fullScreenElement.mozFullScreenElement ||
        this.fullScreenElement.msFullscreenElement
      ) {
        if (this.fullScreenElement.exitFullscreen) {
          this.fullScreenElement.exitFullscreen()
        } else if (this.fullScreenElement.mozCancelFullScreen) {
          this.fullScreenElement.mozCancelFullScreen()
        } else if (this.fullScreenElement.webkitExitFullscreen) {
          this.fullScreenElement.webkitExitFullscreen()
        } else if (this.fullScreenElement.msExitFullscreen) {
          this.fullScreenElement.msExitFullscreen()
        }
      } else if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      ) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
        this.showExpand = true
        this.showNotes2 = false
        this.inFullscreen = false
      } else {
        if (this.fullScreenElement.requestFullscreen) {
          this.fullScreenElement.requestFullscreen()
        } else if (this.fullScreenElement.mozRequestFullScreen) {
          this.fullScreenElement.mozRequestFullScreen()
        } else if (this.fullScreenElement.webkitRequestFullscreen) {
          this.fullScreenElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          )
        } else if (this.fullScreenElement.msRequestFullscreen) {
          this.fullScreenElement.msRequestFullscreen()
        }

        this.showExpand = false
        this.inFullscreen = true
      }
    },
    setPlayerControls() {
      let vuePlyr = document.querySelector('.plyr__controls')
      if (typeof vuePlyr !== 'undefined' && vuePlyr !== null) {
        let checkFullScreen = document.getElementById('fullScreenButton')
        if (
          typeof checkFullScreen !== 'undefined' &&
          checkFullScreen !== null
        ) {
          // Do nothing
        } else {
          let myButton = vuePlyr.appendChild(document.createElement('button'))
          myButton.id = 'fullScreenButton'
          myButton.classList.add('plyr__controls__item', 'plyr__control')
          myButton.type = 'button'

          myButton.addEventListener('click', () => {
            this.togglePlayerFullScreen()
          })

          myButton.innerHTML = `<svg class="icon--pressed" aria-hidden="true" focusable="false"><use xlink:href="#plyr-exit-fullscreen"></use></svg><svg class="icon--not-pressed" aria-hidden="true" focusable="false"><use xlink:href="#plyr-enter-fullscreen"></use></svg><span class="label--pressed plyr__sr-only">Exit fullscreen</span><span class="label--not-pressed plyr__sr-only">Enter fullscreen</span>`
        }
      }
    }
  }
}
</script>
<style>
audio {
  width: 100%;
}
canvas {
  width: 100%;
}
.plyr > .nextVideo {
  background: purple !important;
  display: none !important;
}
.hide-next {
  display: none !important;
}
.v-carousel {
  height: 100vh !important;
}
.v-responsive {
  height: 100vh !important;
}
.expand {
  position: absolute;
  z-index: 999999;
  top: calc(30%);
  right: calc(28%);
}
.collapse {
  position: absolute;
  z-index: 999999;
  top: calc(2%);
  right: calc(2%);
}
.playerToggle {
  display: block;
  position: absolute;
  z-index: 99999;
}
.title-conainer {
  display: block;
  position: absolute;
  z-index: 99999;
}
.playerToggle.showPlayerToggle {
  display: block !important;
}
.ml-18 {
  margin-left: 80px;
}
.component-title {
  z-index: 9;
  position: absolute;
  background: #00000054;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}
</style>
